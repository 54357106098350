import { useNewApplicantContext } from "context/NewApplicantContext";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import MDBox from "components/MDBox";
import DataListContainer from "components/DataListContainer";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { applicantSchema } from "data/applicant";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import { useQuery } from "react-query";
import { useAppContextController } from "context/AppContext";
import useSessionAuth from "hooks/useSessionAuth";
import ResultCard from "layouts/pages/web/components/ResultCard";
import OtherJobsItem from "../OtherJobsItem";
import ApplicationListItem from "../ApplicationListItem";

const JobApplicationAndInterview = () => {
    const {
        applicant,
        updateApplicantAction,
        updateButtons,
        buttonState,
        updateCurrentFormState,
        currentFormState,
    } = useNewApplicantContext();

    const { venues, company, companyType } = useAppContextController();
    const resolver = useYupValidationResolver(applicantSchema);
    const [uniqueCompanies, setUniqueCompanies] = useState({});
    const [searchFilter, setSearchFilter] = useState(null);
    const [page, setPage] = useState(1);
    const options = { page, limit: 5 };
    const allOptionsFiltered = {
        ...options,
        filters: {},
        venues,
        useOr: true,
    };
    const searchFilterWithDefault = useMemo(() => {
        if (searchFilter && Object.keys(searchFilter).length) {
            return searchFilter;
        }
        if (company) {
            return companyType === "Venue"
                ? { venueCity: "", venueState: "" }
                : { companyState: "", companyCity: "" };
        }
        return null;
    }, [company, companyType, searchFilter]);
    const { data: jobsFiltered, isLoading: isLoadingFiltered } = useQuery(
        ["jobresultsfiltered", { ...allOptionsFiltered, outsideMode: "public" }],
        () => fetchJobs({ ...allOptionsFiltered, outsideMode: "public" }),
        {
            refetchOnWindowFocus: false,
            enabled: searchFilterWithDefault != null,
            cacheTime: 0,
        }
    );

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        setError,
        clearErrors,
        reset,
        watch,
        trigger,
        formState: { errors, isDirty, isSubmitSuccessful, isValid },
    } = useForm({ resolver });

    const renderApplicationList = (row, idx) => (
        <ApplicationListItem
            row={row}
            idx={idx}
            parent="job"
            companyType={companyType}
        // onEdit={handleJobHistory}
        // onRemove={handleRemoveJobHistory}
        />
    );
    const renderInterviewList = (row, idx) => (
        <ApplicationListItem
            row={row}
            idx={idx}
            parent="interview"
            companyType={companyType}
        // onEdit={handleJobHistory}
        // onRemove={handleRemoveJobHistory}
        />
    );

    useEffect(() => {
        updateCurrentFormState({
            ...currentFormState,
            isDirty,
        });

        updateButtons({
            ...buttonState,
            submit: {
                show: true,
                disabled: !isDirty,
            },
        });
    }, [isDirty]);

    useEffect(() => {
        updateButtons({
            ...buttonState,
            previous: {
                show: true,
                disabled: false,
            },
            next: {
                show: true,
                disabled: false,
            },
            submit: {
                show: true,
                disabled: false,
            },
        });
    }, []);

    useEffect(() => {
        if (isValid) {
            updateButtons({
                ...buttonState,
                next: {
                    show: true,
                    disabled: false,
                },
            });
        }
    }, [isValid]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            updateButtons({
                ...buttonState,
                next: {
                    show: true,
                    disabled: false,
                },
                submit: {
                    show: true,
                    disabled: false,
                },
            });
        }
    }, [isSubmitSuccessful]);





    return (
        <>

            <DataListContainer

                noCreate
                data={applicant?.jobs}
                renderRow={renderApplicationList}
                title="Application List"

            />
            <DataListContainer

                noCreate
                data={applicant?.interviews}
                renderRow={renderInterviewList}
                title="Interview List"

            />

        </>
    )
}

export default JobApplicationAndInterview