import { useAppContextController } from "context/AppContext";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useCompanyCache from "hooks/useCompanyCache";
import CompanyTable from "layouts/pages/companies/components/CompanyTable";
import useCompanyStore from "stores/useCompanyStore";
import CompanyInfoPanel from "./components/CompanyInfoPanel";
import CompanyMedia from "./components/CompanyMedia";

const CompanyPage = ({ mode = "edit" }) => {
  const navigate = useNavigate();
  const { setDataType } = useCompanyStore((state) => ({
    setDataType: state.setDataType,
  }));
  const { company, setCompany, setCompanyType } = useAppContextController();
  const { refetchCompany } = useCompanyCache({ company, setCompany, setCompanyType });
  const { id, action } = useParams();
  const [selectedId, setSelectedId] = useState(id);
  const [actionSelected, setActionSelected] = useState(action || "");
  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState({});

  useEffect(() => {
    setSelectedId(id);
  }, [id]);

  useEffect(() => {
    if (id && (actionSelected || action)) {
      setFilters({ _id: selectedId });
    }
    if (action) {
      setActionSelected(action);
    } else {
      setActionSelected("");
    }
  }, [selectedId, action, actionSelected, id]);

  useEffect(() => {
    setDataType("company");
  }, []);

  const setNavigateUrl = (url) => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12}>
          {/* <CompanyFilterCards setFilters={setFilters} setPage={setPage} /> */}
          <CompanyTable
            id={id}
            filters={filters}
            setFilters={setFilters}
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            title="Companies"
            page={page}
            setPage={setPage}
          />
        </Grid>

        {["companyinfo", "companyall", ""].includes(actionSelected) && (id || mode === "create") && (
          <Grid item xs={12} sm={12}>
            <CompanyInfoPanel
              mode={mode} // no props
            />
          </Grid>
        )}
        {["companymedia", "companyall", ""].includes(actionSelected) && (id || mode === "create") && (
          <Grid item xs={12} sm={12}>
            <CompanyMedia
              sourceComponent="Company"
              company={company}
              refetchCompany={refetchCompany}
            />
          </Grid>
        )}
      </Grid>
    </DashboardLayout>
  );
};

export default CompanyPage;
