import { Button, Grid, Icon, Stack } from "@mui/material";
import moment from "moment";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const ApplicationListItem = ({ row, idx, onEdit, parent, companyType }) => {

    const { venueName, companyName, title, jobName, eventDate, applyDate, modifiedDate, status } = row;
    const date = (parent === "job") ? applyDate || modifiedDate : eventDate
    return (
        <>
            <Stack
                display="flex"
                direction="row"
                justifyContent="space-between"
                onClick={() => {
                    onEdit(idx, row, true);
                }}
            >
                <Grid container display="flex" alignItems="center">
                    <Grid item sm={12}>
                        <Grid container justifyContent="left" alignItems="center" spacing={1}>
                            <Grid item sm={3}>
                                <MDTypography sx={{ fontSize: "0.8rem" }}>{companyType === "Venue" ? venueName : companyName}</MDTypography>
                            </Grid>

                            <Grid item sm={3} textAlign="center">
                                <MDTypography sx={{ fontSize: "0.8rem" }}>{parent === "job" ? title : jobName}</MDTypography>
                            </Grid>

                            <Grid item sm={3} textAlign="end">
                                <MDTypography sx={{ fontSize: "0.8rem" }}>
                                    {moment(date).format("MM/DD/YYYY")}
                                </MDTypography>
                            </Grid>
                            <Grid item sm={3} textAlign="end">
                                <MDTypography sx={{ fontSize: "0.8rem" }}>
                                    {status}
                                </MDTypography>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </Stack>
        </>
    );
};

export default ApplicationListItem;
