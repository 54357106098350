import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Searchbar from "components/Searchbar";
import VenueIcon from "components/VenueIcon";
import VenueStatusButton from "components/VenueStatusButton";
import { useAppContextController } from "context/AppContext";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import useSort from "utils/useSort";
import ApplicantVenuesTableActions from "layouts/pages/applicants/components/ApplicantVenuesTableActions";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import {
  generateApplicantVenueChartReport,
  generateApplicantVenueTableReport,
  generateEmployeeVenueChartReport,
  generateEmployeeVenueTableReport,
} from "api/reports/venueReport";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import { exportReport } from "api/reports/exportReport";
import { saveReport } from "api/reports/saveReport";
import useSessionAuth from "hooks/useSessionAuth";
import fetchModuleReports from "api/reports/fetchModuleReports";
import moment from "moment";
import ReportModal from "components/ReportModal";
import useHandleBackButtonSubGrid from "hooks/useHandleBackButtonSubGrid";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const ApplicantVenuesTable = ({
  slug,
  fetchAll = true,
  setNavigateUrl,
  title = "Venues Table",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  showVenueStatus = false,
  currentApplicant = null,
  setOpen,
  setVenuePreview,
  setToastAlert,
  parent,
  name,
  parentPage,
  parentNavigateWithBack,
}) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const {
    venues: venueCache,
    setVenues,
    setCurrentVenue,
    currentVenue,
    company,
    userType,
  } = useAppContextController();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  // const [prevPage, setPrevPage] = useState(1)
  const { order, orderBy, toggleSort } = useSort();

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters, setVenues, imageUrlBase: company?.imageUrl };

  const { data: venues, isLoading } = useQuery(["applicantVenues", allOptions], () =>
    fetchVenues(allOptions)
  );

  // Handle back button from parent screens
  const { navigateWithBack } = useHandleBackButtonSubGrid({
    parentPage,
    setPage,
    page,
    filters,
    navigateFunc: setNavigateUrl ?? navigate,
    navigateWithBackFunc: parentNavigateWithBack,
  });

  const localVenues = venueCache ? Object.entries(venueCache).map((item) => item[1]) : [];

  useEffect(() => {
    if (!isLoading && localVenues?.length && slug) {
      const ndx = localVenues.findIndex((item) => item.slug === slug);
      if (ndx > -1) {
        setCurrentVenue(localVenues[ndx]);
      }
    }
  }, [isLoading, slug]);

  useEffect(() => {
    setPage(1);
  }, [filters, action]);

  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);
  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);

  const { mutateAsync: createApplicantVenueTableReport } = useMutation(
    generateApplicantVenueTableReport
  );
  const { mutateAsync: createApplicantVenueChartReport } = useMutation(
    generateApplicantVenueChartReport
  );
  const { mutateAsync: createEmployeeVenueTableReport } = useMutation(
    generateEmployeeVenueTableReport
  );
  const { mutateAsync: createEmployeeVenueChartReport } = useMutation(
    generateEmployeeVenueChartReport
  );
  const { mutateAsync: exportApplicantVenueReport, isLoading: isLoadingExport } =
    useMutation(exportReport);
  const { mutateAsync: saveApplicantVenueReport, isLoading: isLoadingSave } =
    useMutation(saveReport);

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);

  const { logoutAuth0User } = useSessionAuth();

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const handleLoadInitialApplicantVenueReport = useCallback(async () => {
    if (openReportMessageModal) {
      try {
        let reportModule;

        if (parent === "employees") {
          reportModule = `employee-venues:${currentApplicant?._id}`;
        } else {
          reportModule = `applicant-venues:${currentApplicant?._id}`;
        }

        const response = await fetchModuleReports(reportModule);

        const latestCriteria = getLatestCriteria(
          response?.tableReportData,
          response?.chartReportData,
          defaultReportModalValues
        );
        setReportModalValues(latestCriteria);

        // Set table details
        if (response?.tableReportData) {
          setChartTableSrc(response.tableReportData.queryDetails.iframeSrc);
          setTableQueryDetails(response.tableReportData.queryDetails);
          setTableReportData(response.tableReportData);
        }

        // Set chart details
        if (response?.chartReportData) {
          setChartSrc(response.chartReportData.queryDetails.iframeSrc);
          setChartQueryDetails(response.chartReportData.queryDetails);
          setChartReportData(response.chartReportData);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReportMessageModal]);

  useEffect(() => {
    handleLoadInitialApplicantVenueReport();
  }, [handleLoadInitialApplicantVenueReport]);

  const handleExportReport = useCallback(
    async (reportType, fileType) => {
      try {
        const payload = {
          queryId: reportType === "table" ? tableQueryDetails?.id : chartQueryDetails?.id,
          fileType,
        };

        let reportModule;

        if (parent === "employees") {
          reportModule = `Employee`;
        } else {
          reportModule = `Applicant`;
        }

        await exportApplicantVenueReport(payload, {
          onSuccess: async (response) => {
            setToastAlert({
              isShow: true,
              message: `${reportModule} Venue ${reportType === "table" ? "Table" : "Chart"} ${
                fileType === "csv" ? " CSV" : " Excel"
              } Report has been successfully exported!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error exporting report:", error);
      }
    },
    [
      chartQueryDetails?.id,
      exportApplicantVenueReport,
      parent,
      setToastAlert,
      tableQueryDetails?.id,
    ]
  );

  const handleGenerateReport = async (values) => {
    if (values?.formType === "table") {
      let reportPayload = {};

      // Add columns to payload if any are selected
      if (values?.tableColumns?.length > 0) {
        reportPayload = { ...reportPayload, columns: values?.tableColumns };
      }

      // Add sortBy and order to payload if available
      if (values?.sortBy) {
        reportPayload = { ...reportPayload, sortBy: values.sortBy };
      }

      if (values?.order) {
        reportPayload = { ...reportPayload, order: values.order };
      }

      if (values?.dateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.startDate, // ISO formatted date already handled
            end: values?.endDate, // ISO formatted date already handled
            field: values?.dateField,
            range: values?.dateRange,
          },
        };
      }

      // Add filters to payload if any filters are provided
      if (values?.filters) {
        reportPayload = {
          ...reportPayload,
          filters: values?.filters,
        };
      }

      if (values?.filterCards) {
        reportPayload = {
          ...reportPayload,
          filterCards: values?.filterCards,
        };
      }

      try {
        if (currentApplicant?._id) {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          if (parent === "employees") {
            await createEmployeeVenueTableReport(reportPayload, {
              onSuccess: async (response) => {
                if (response?.iframe_url) {
                  setChartTableSrc(response?.iframe_url);
                  setTableQueryDetails(response?.queryDetails);
                  setTableReportData(response?.reportData);
                }

                setToastAlert({
                  isShow: true,
                  message: "Employee Venue Table Report has been successfully generated!",
                  status: "success",
                });
              },
              onError: (err) => {
                setToastAlert({
                  isShow: true,
                  message: `Something went wrong! ${err}`,
                  status: "error",
                });
              },
            });
          } else {
            await createApplicantVenueTableReport(reportPayload, {
              onSuccess: async (response) => {
                if (response?.iframe_url) {
                  setChartTableSrc(response?.iframe_url);
                  setTableQueryDetails(response?.queryDetails);
                  setTableReportData(response?.reportData);
                }

                setToastAlert({
                  isShow: true,
                  message: "Applicant Venue Table Report has been successfully generated!",
                  status: "success",
                });
              },
              onError: (err) => {
                setToastAlert({
                  isShow: true,
                  message: `Something went wrong! ${err}`,
                  status: "error",
                });
              },
            });
          }
        }
      } catch (error) {
        console.error("Error generating table report:", error);
      }
    } else {
      let reportPayload = {
        xAxis: values?.xAxis,
        yAxis: values?.yAxis,
        groupBy: values?.groupBy,
        chartType: values?.chartType,
      };

      try {
        if (currentApplicant?._id) {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          if (parent === "employees") {
            await createEmployeeVenueChartReport(reportPayload, {
              onSuccess: async (response) => {
                if (response?.iframe_url) {
                  setChartSrc(response?.iframe_url);
                  setChartQueryDetails(response?.queryDetails);
                  setChartReportData(response?.reportData);
                }

                setToastAlert({
                  isShow: true,
                  message: "Employee Venue Chart Report has been successfully generated!",
                  status: "success",
                });
              },
              onError: (err) => {
                setToastAlert({
                  isShow: true,
                  message: `Something went wrong! ${err}`,
                  status: "error",
                });
              },
            });
          } else {
            await createApplicantVenueChartReport(reportPayload, {
              onSuccess: async (response) => {
                if (response?.iframe_url) {
                  setChartSrc(response?.iframe_url);
                  setChartQueryDetails(response?.queryDetails);
                  setChartReportData(response?.reportData);
                }

                setToastAlert({
                  isShow: true,
                  message: "Applicant Venue Chart Report has been successfully generated!",
                  status: "success",
                });
              },
              onError: (err) => {
                setToastAlert({
                  isShow: true,
                  message: `Something went wrong! ${err}`,
                  status: "error",
                });
              },
            });
          }
        }
      } catch (error) {
        console.error("Error generating chart report:", error);
      }
    }
  };

  const handleSaveReport = useCallback(
    async (values) => {
      try {
        let payload = {
          criteria: values,
        };

        let _tableReportData = tableReportData;
        let _chartReportData = chartReportData;

        if (currentApplicant?._id) {
          if (parent === "employees") {
            _tableReportData = {
              ..._tableReportData,
              feature: `employee-venue:${currentApplicant?._id}`,
            };

            _chartReportData = {
              ..._chartReportData,
              feature: `employee-venues:${currentApplicant?._id}`,
            };
          } else {
            _tableReportData = {
              ..._tableReportData,
              feature: `applicant-venues:${currentApplicant?._id}`,
            };

            _chartReportData = {
              ..._chartReportData,
              feature: `applicant-venues:${currentApplicant?._id}`,
            };
          }
        }

        if (values?.formType === "table") {
          payload = { ...payload, tableReportData: _tableReportData, formType: "table" };
        } else if (values?.formType === "chart") {
          payload = { ...payload, chartReportData: _chartReportData, formType: "chart" };
        }

        await saveApplicantVenueReport(payload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            let reportModule;

            if (parent === "employees") {
              reportModule = "Employee";
            } else {
              reportModule = "Applicant";
            }

            setToastAlert({
              isShow: true,
              message: `${reportModule} Venue ${
                values?.formType === "table" ? "Table" : "Chart"
              } Report has been successfully saved!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error saving venue applicant report:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartReportData, saveApplicantVenueReport, tableReportData]
  );

  const renderTitle = () => {
    const role = parent === "employees" ? "Employee" : "Applicant";
    return `${role} Venues Report for ${name}`;
  };

  const columns = useMemo(
    () => [
      {
        title: "Logo",
        field: "logoUrl",
        imageWidth: "50",
        imageHeight: "50",
        customCell: (logoUrl, field, row) => (
          <VenueIcon
            logoUrl={logoUrl}
            slug={row.slug}
            name={row.name}
            imageWidth="50"
            imageHeight="50"
          />
        ),
      },
      ...(showVenueStatus && currentApplicant
        ? [
            {
              title: "Venue",
              field: "slug",
              customCell: (value, field, row) => (
                <VenueStatusButton
                  status={getApplicantVenueStatus(currentApplicant, value)}
                  venue={row}
                />
              ),
            },
          ]
        : []),
      {
        title: "Venue ID",
        field: "slug",
        customCell: (thisSlug) => <span>{thisSlug?.toUpperCase()}</span>,
      },
      { title: "Venue Name", field: "name" },
      { title: "Venue URL", field: "venueUrl" },
      { title: "City", field: "city" },
      { title: "State", field: "state" },
      {
        title: "Actions",
        noSort: true,
        field: "slug",
        customCell: (thisSlug, field, row) => (
          <td>
            <ApplicantVenuesTableActions
              slug={thisSlug}
              row={row}
              setFilters={setFilters}
              setOpen={setOpen}
              setVenuePreview={setVenuePreview}
              setNavigateUrl={navigateWithBack}
            />
          </td>
        ),
      },
    ],
    [currentApplicant]
  );

  const venueColumns = useMemo(
    () => [
      {
        title: "Logo",
        field: "logoUrl",
        customCell: (logoUrl, field, row) => (
          <VenueIcon logoUrl={logoUrl} slug={row?.slug} name={row?.name} />
        ),
      },
      {
        title: "Venue Status",
        field: "slug",
        customCell: (value, row) => (
          <VenueStatusButton
            status={getApplicantVenueStatus(currentApplicant, value)}
            venue={row}
          />
        ),
      },
      {
        title: "Venue",
        field: "slug",
        customCell: (venue) => <span>{venue?.toUpperCase()}</span>,
      },
      {
        title: "Venue Name",
        field: "name",
      },
      {
        title: "City",
        field: "city",
      },
      {
        title: "State",
        field: "state",
      },
      { title: "Status", field: "status" },
    ],
    [currentApplicant]
  );

  const handleRowClick = (venue, e) => {
    setCurrentVenue(venue);
    navigateWithBack(`/venues/${venue.slug}`);
    e.stopPropagation();
  };

  const addNew = (e) => {
    if (!currentVenue) {
      setCurrentVenue({});
    }
    navigateWithBack("/venues/create");
    e.stopPropagation();
  };

  useEffect(() => {
    if (!openReportMessageModal) {
      setChartTableSrc("");
      setChartSrc("");
    }
  }, [openReportMessageModal]);

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item>
          <MDBox display="flex" mt={0} mb={0}>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="info"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <Icon fontSize="large">stadium</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              {title}
            </MDTypography>

            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center">
          <Searchbar
            fetch={fetchVenues}
            fetchAll
            placeholder="Add Venues"
            columns={venueColumns}
            queryCharacterLimit={1}
            resultsLimit={10}
            setFilters={setFilters}
            setPage={setPage}
            searchBy={["slug", "name", "city"]}
            // filterBy="venueSlug"
            // filterByColumn="slug"
            idField="slug"
            // onRowClick={(venue) => {
            //   setCurrentEvent(null);
            //   navigate(`/events/venue/${venue.slug}`)
            // }}
          />
          <MDBox>
            <IconButton className={classes.addButton} color="info" onClick={(e) => addNew(e)}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>

          {userType !== "User" && userType !== "Client" && userType !== "Event Admin" && (
            <Tooltip title="Create Report">
              <IconButton color="info" onClick={() => setOpenReportMessageModal(true)}>
                <ViewComfyIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={venues}
          selected={currentVenue}
          onRowClick={(row, e) => handleRowClick(row, e)}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          isLoading={isLoading} // Extract isLoading from useQuery then pass here (required)
          defaultSort="name"
          greybar
          divider
        />
      </MDBox>
      {pageLimitConfirmationModal}

      {openReportMessageModal && (
        <ReportModal
          reportingType="venues"
          message="Coming Soon!"
          title={renderTitle()}
          isOpen={openReportMessageModal}
          toggleOpen={setOpenReportMessageModal}
          handleGenerateReport={handleGenerateReport}
          chartTableSrc={chartTableSrc}
          chartSrc={chartSrc}
          handleExportReport={handleExportReport}
          handleSaveReport={handleSaveReport}
          isLoadingExport={isLoadingExport}
          isLoadingSave={isLoadingSave}
          reportModalValues={reportModalValues}
        />
      )}
    </Card>
  );
};

// Setting default values for the props
ApplicantVenuesTable.defaultProps = {
  // currentVenue: {},
  fetchAll: true,
  setNavigateUrl: () => {},
  title: "Venues",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
ApplicantVenuesTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  setNavigateUrl: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default ApplicantVenuesTable;
