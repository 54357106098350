import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import Searchbar from "components/Searchbar";
import { useAppContextController } from "context/AppContext";
import fetchPartnerApplicants from "layouts/pages/applicants/actions/fetchPartnerApplicants";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";

import PartnerApplicantsTableActions from "layouts/pages/partners/components/PartnerApplicantsTableActions";
import moment from "moment";
import { SnackbarProvider } from "notistack";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { memberSchema } from "data/member";
import useSort from "utils/useSort";
import TaskDetailsModal from "layouts/pages/tasks/components/TaskDetailsModal";
import TaskFlag from "components/TaskFlag";
import {
  generatePartnerApplicantChartReport,
  generatePartnerApplicantTableReport,
} from "api/reports/partnerReport";
import { exportReport } from "api/reports/exportReport";
import { saveReport } from "api/reports/saveReport";
import useSessionAuth from "hooks/useSessionAuth";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import fetchModuleReports from "api/reports/fetchModuleReports";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import ReportModal from "components/ReportModal";
import MembersModal from "../MembersModal";
import ApplicantNotesList from "../../../../../components/ApplicantNotesList";
import MemberPartnerStatus from "../MemberPartnerStatus";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const PartnerApplicantsTable = ({
  fetchAll = false,
  title = "Partner Applicants",
  filters,
  pageSize = 5,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  partnerSlug = null,
  showEventStatus = false,
  setSelectedId,
  staffingPoolName,
  setToastAlert,
}) => {
  const queryClient = useQueryClient();
  const [dropdowns, setDropdowns] = useState();
  const resolver = useYupValidationResolver(memberSchema(dropdowns));
  const { action, id } = useParams();
  const setNavigateUrl = useNavigate();
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const classes = useStyle();
  const { currentPartner, userType } = useAppContextController();
  const [currentMember, setCurrentMember] = useState(null);
  const [modalInfo, setModalInfo] = useState({ data: null });
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false);
  const [isStaffingPoolModalOpen, toggleStaffingPoolModalOpen] = useState(false);
  const [applicantsFilters, setApplicantsFilters] = useState({});
  const [candidates, setCandidates] = useState({});
  const [membersModalOpen, setMembersModalOpen] = useState(false);
  const [memberNotesListOpen, setMemberNotesListOpen] = useState(false);
  const [memberFilters, setMemberFilters] = useState({});
  const [taskModalOpen, setTaskModalOpen] = useState(false);

  // Pagination state

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(pageSize); // Default pageSize higher than 10. Don't use preferred pageSize
  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);
  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);

  const { logoutAuth0User } = useSessionAuth();

  const { mutateAsync: createPartnerApplicantTableReport } = useMutation(
    generatePartnerApplicantTableReport
  );
  const { mutateAsync: createPartnerApplicantChartReport } = useMutation(
    generatePartnerApplicantChartReport
  );
  const { mutateAsync: exportPartnerApplicantReport, isLoading: isLoadingExport } =
    useMutation(exportReport);
  const { mutateAsync: savePartnerApplicantReport, isLoading: isLoadingSave } =
    useMutation(saveReport);

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);

  const { order, orderBy, toggleSort } = useSort();

  const options = fetchAll ? { fetchAll, order, orderBy } : { page, limit, order, orderBy };
  const allOptions = {
    ...options,
    filters: {
      status: "Member",
      "partners.partnerSlug": partnerSlug,
    },
  };

  const {
    data: applicants,
    isLoading,
    refetch: refetchPartnerApplicants,
  } = useQuery(["partnersapplicants", allOptions], () => fetchPartnerApplicants(allOptions));

  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    getValues,
    reset,
    setValue,
    setError,
    control,
    watch,
    Control,
    formState: { errors, isSubmitting, isDirty, dirtyFields },
  } = useForm({ mode: "onBlur", defaultValues: currentMember, resolver });

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const handleLoadInitialPartnersApplicantPoolReport = useCallback(async () => {
    if (openReportMessageModal) {
      try {
        const slug = filters["partners.partnerSlug"] || partnerSlug;

        const response = await fetchModuleReports(`partners-applicant:${slug}`);

        const latestCriteria = getLatestCriteria(
          response?.tableReportData,
          response?.chartReportData,
          defaultReportModalValues
        );
        setReportModalValues(latestCriteria);

        // Set table details
        if (response?.tableReportData) {
          setChartTableSrc(response.tableReportData.queryDetails.iframeSrc);
          setTableQueryDetails(response.tableReportData.queryDetails);
          setTableReportData(response.tableReportData);
        }

        // Set chart details
        if (response?.chartReportData) {
          setChartSrc(response.chartReportData.queryDetails.iframeSrc);
          setChartQueryDetails(response.chartReportData.queryDetails);
          setChartReportData(response.chartReportData);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReportMessageModal]);

  useEffect(() => {
    handleLoadInitialPartnersApplicantPoolReport();
  }, [handleLoadInitialPartnersApplicantPoolReport]);

  const handleExportReport = useCallback(
    async (reportType, fileType) => {
      try {
        const payload = {
          queryId: reportType === "table" ? tableQueryDetails?.id : chartQueryDetails?.id,
          fileType,
        };

        await exportPartnerApplicantReport(payload, {
          onSuccess: async (response) => {
            setToastAlert({
              isShow: true,
              message: `Partner Applicant ${reportType === "table" ? "Table" : "Chart"} ${
                fileType === "csv" ? " CSV" : " Excel"
              } Report has been successfully exported!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error exporting report:", error);
      }
    },
    [chartQueryDetails?.id, exportPartnerApplicantReport, tableQueryDetails?.id]
  );

  const handleGenerateReport = async (values) => {
    if (values?.formType === "table") {
      let reportPayload = {};

      // Add columns to payload if any are selected
      if (values?.tableColumns?.length > 0) {
        reportPayload = { ...reportPayload, columns: values?.tableColumns };
      }

      // Add sortBy and order to payload if available
      if (values?.sortBy) {
        reportPayload = { ...reportPayload, sortBy: values.sortBy };
      }

      if (values?.order) {
        reportPayload = { ...reportPayload, order: values.order };
      }

      if (values?.dateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.startDate, // ISO formatted date already handled
            end: values?.endDate, // ISO formatted date already handled
            field: values?.dateField,
            range: values?.dateRange,
          },
        };
      }

      // Add filters to payload if any filters are provided
      if (values?.filters) {
        reportPayload = {
          ...reportPayload,
          filters: values?.filters,
        };
      }

      if (values?.filterCards) {
        reportPayload = {
          ...reportPayload,
          filterCards: values?.filterCards,
        };
      }

      try {
        const slug = filters["partners.partnerSlug"] || partnerSlug;

        if (slug) {
          reportPayload = {
            ...reportPayload,
            partnerSlug: slug,
          };

          await createPartnerApplicantTableReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartTableSrc(response?.iframe_url);
                setTableQueryDetails(response?.queryDetails);
                setTableReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: `Partner Applicant Table Report has been successfully generated!`,
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        }
      } catch (error) {
        console.error("Error generating table report:", error);
      }
    } else {
      let reportPayload = {
        xAxis: values?.xAxis,
        yAxis: values?.yAxis,
        groupBy: values?.groupBy,
        chartType: values?.chartType,
      };

      try {
        const slug = filters["partners.partnerSlug"] || partnerSlug;

        if (slug) {
          reportPayload = {
            ...reportPayload,
            partnerSlug: slug,
          };

          await createPartnerApplicantChartReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartSrc(response?.iframe_url);
                setChartQueryDetails(response?.queryDetails);
                setChartReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: `Partner Applicant Chart Report has been successfully generated!`,
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        }
      } catch (error) {
        console.error("Error generating chart report:", error);
      }
    }
  };

  const handleSaveReport = useCallback(
    async (values) => {
      try {
        let payload = {
          criteria: values,
        };

        let _tableReportData = tableReportData;
        let _chartReportData = chartReportData;

        const slug = filters["partners.partnerSlug"] || partnerSlug;

        if (slug) {
          _tableReportData = {
            ..._tableReportData,
            feature: `partners-applicant:${slug}`,
          };

          _chartReportData = {
            ..._chartReportData,
            feature: `partners-applicant:${slug}`,
          };
        }

        if (values?.formType === "table") {
          payload = { ...payload, tableReportData: _tableReportData, formType: "table" };
        } else if (values?.formType === "chart") {
          payload = { ...payload, chartReportData: _chartReportData, formType: "chart" };
        }

        await savePartnerApplicantReport(payload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: `Partner Applicant ${
                values?.formType === "table" ? "Table" : "Chart"
              } Report has been successfully saved!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error(`Error saving Partner Applicant report:`, error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartReportData, savePartnerApplicantReport, tableReportData]
  );

  const columns = [
    {
      title: "Member",
      field: "rosterStatus",
      customCell: (eurl, field, row) => {
        // get memberStatus for this row
        const partners = row?.partners ? row.partners : [];
        const thisPartner = partners.find(
          (partner) => partner.partnerSlug === currentPartner?.slug
        );
        return (
          <MemberPartnerStatus
            id={row?._id}
            status={thisPartner?.status}
            setToastAlert={setToastAlert}
            queryClient={queryClient}
            member={row}
            source="membersTable"
            showTooltip={false}
            refetchPartnerApplicants={refetchPartnerApplicants}
          />
        );
      },
    },
    {
      title: "Avatar",
      field: "profileImg",
      customCell: (image, field, row) => (
        <PictureAvatar
          image={row?.profileImg}
          firstName={row?.firstName}
          lastName={row?.lastName}
          // userId={row?.userRecordId ? row.userRecordId : row?._id}
          size="md"
        />
      ),
    },
    {
      title: "Flag",
      field: "_id",
      width: 15,
      customCell: (_id, field, row) => <TaskFlag _id={_id} />,
    },
    {
      title: "Status",
      field: "partners",
      customCell: (partnersArray) => {
        const thisPartner = partnersArray.find(
          (partner) => partner.partnerSlug === currentPartner?.slug
        );
        return <> {thisPartner?.status}</>;
      },
    },
    {
      title: "Last Name",
      field: "lastName",
    },
    {
      title: "First Name",
      field: "firstName",
    },
    { title: "Phone", field: "phone" },
    { title: "Email", field: "email" },
    {
      title: "T-Shirt Size",
      field: "tShirtSize",
    },
    // {
    //   title: "Date",
    //   field: "createdDate",
    //   customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    // },
    {
      title: "Actions",
      field: "_id",
      customCell: (rowId, field, row) => (
        <PartnerApplicantsTableActions
          id={rowId}
          row={row}
          setCurrentApplicant={setCurrentMember}
          setModalInfo={setModalInfo}
          setNavigateUrl={setNavigateUrl}
          setGenericModalOpen={setGenericModalOpen}
          setMembersModalOpen={setMembersModalOpen}
          setMemberNotesListOpen={setMemberNotesListOpen}
        />
      ),
    },
  ];

  const searchColumns = [
    // {
    //   title: "Avatar",
    //   field: "profileImg",
    //   customCell: (image, field, row) => (
    //     <PictureAvatar
    //       image={row?.profileImg}
    //       firstName={row?.firstName}
    //       lastName={row?.lastName}
    //       // userId={row?.userRecordId ? row.userRecordId : row?._id}
    //       size="md"
    //     />
    //   ),
    // },
    {
      title: "Member",
      field: "rosterStatus",
      customCell: (eurl, row) => {
        // get memberStatus for this row
        const partners = row?.partners ? row.partners : [];
        const thisPartner = partners.find(
          (partner) => partner?.partnerSlug === currentPartner?.slug
        );

        return (
          <MemberPartnerStatus
            id={row?._id}
            status={thisPartner?.status}
            setToastAlert={setToastAlert}
            queryClient={queryClient}
            member={row}
            source="searchBar"
            showTooltip={false}
            refetchPartnerApplicants={refetchPartnerApplicants}
          />
        );
      },
    },

    {
      title: "Last Name",
      field: "lastName",
      // customCell: (name, field, row) =>
      //   row ? (
      //     <CustomWidthTooltip
      //       title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
      //       sx={{ color: "red" }}
      //     >
      //       <div>{name}</div>
      //     </CustomWidthTooltip>
      //   ) : null,
    },
    {
      title: "First Name",
      field: "firstName",
      // customCell: (name, field, row) =>
      //   row ? (
      //     <CustomWidthTooltip
      //       title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
      //       sx={{ color: "red" }}
      //     >
      //       <div>{name}</div>
      //     </CustomWidthTooltip>
      //   ) : null,
    },
    {
      title: "Date",
      field: "createdDate",
      customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    // {
    //   title: "Partner Actions",
    //   field: "_id",
    //   customCell: (rowId, field, row) => (
    //     <PartnerApplicantsTableActions
    //       id={rowId}
    //       row={row}
    //       setCurrentApplicant={setCurrentApplicant}
    //       setModalInfo={setModalInfo}
    //       setNavigateUrl={setNavigateUrl}
    //       setGenericModalOpen={setGenericModalOpen}
    //     />
    //   ),
    // },
  ];

  const handleRowClick = (row, e) => {
    setCurrentMember(row);
    setMembersModalOpen(true);
  };

  const addNew = (e) => {
    setCurrentMember(null);

    setMembersModalOpen(true);
    e.stopPropagation();
  };

  const handleNotesSuccess = () => {
    queryClient.invalidateQueries(["partnersapplicants"]);
    queryClient.invalidateQueries(["members"]);
  };

  const sortLastFirst = (a, b) => {
    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  };

  useEffect(() => {
    if (!applicants) {
      setCandidates([]);
      return;
    }
    setCandidates(applicants);
  }, [applicants]);

  useEffect(() => {
    if (!openReportMessageModal) {
      setChartTableSrc("");
      setChartSrc("");
    }
  }, [openReportMessageModal]);

  return (
    <>
      <Card className={classes.box}>
        <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
          <Grid item>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="warning"
                color="white"
                borderRadius="xl"
                ml={3}
                mt={-4}
              >
                <Icon fontSize="large">people</Icon>
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                {title}
              </MDTypography>
              <MDBox ml={3}>
                {showFiltersList && (
                  <Grid item xs={6}>
                    <FiltersList filters={memberFilters} setFilters={setMemberFilters} />
                  </Grid>
                )}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item display="flex" alignItems="center">
            <Searchbar
              fetch={fetchPartnerApplicants}
              fetchAll={false}
              placeholder="Search Members"
              columns={searchColumns}
              queryCharacterLimit={3}
              resultsLimit={15}
              setFilters={setMemberFilters}
              setPage={setPage}
              searchBy={["firstName", "lastName"]}
              filterBy="_id"
              scrollLimit={200}
              sortFunction={sortLastFirst}
              // onRowClick={(item) => {
              //   if (item?._id) setSelectedId(item._id);
              // }}
            />

            <MDBox>
              {/* <Tooltip title="Bulk Message">
                  <IconButton color="error" onClick={() => toggleBulkMessageModal(true)}>
                    <Mail fontSize="large" />
                  </IconButton>
                </Tooltip>
                {currentPartner?.slug && (
                  <Tooltip title={`Export ${staffingPoolName} Staffing Pool`}>
                    <IconButton color="info" onClick={() => toggleStaffingPoolModalOpen(true)}>
                      <FileDownload />
                    </IconButton>
                  </Tooltip>
                )} */}
              <Tooltip title="Create a New Member">
                <IconButton className={classes.addButton} color="info" onClick={addNew}>
                  <AddCircleRoundedIcon />
                </IconButton>
              </Tooltip>
            </MDBox>

            {userType !== "User" && userType !== "Client" && userType !== "Event Admin" && (
              <Tooltip title="Create Report">
                <IconButton color="info" onClick={() => setOpenReportMessageModal(true)}>
                  <ViewComfyIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <MDBox ml={3}>
          {/* <MDBox display="flex" flexWrap="wrap" alignItems="center" rowGap="1rem">
              <ApplicantsFilterCards
                parent="Partners"
                setFilters={setApplicantsFilters}
                filters={{ ...filters, ...applicantsFilters }}
              />
              <VenueSearch
                data={candidates}
                setData={setCandidates}
                fetchedData={applicants}
                setPage={setPage}
                applicantsFilters={applicantsFilters}
                label={type === "Applicants" ? "Applicants" : "Staff"}
              />
            </MDBox> */}

          <MDBox pt={2} pb={2}>
            <DataTable
              columns={columns}
              data={candidates}
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              isLoading={isLoading}
              onRowClick={(row, e) => handleRowClick(row, e)}
              order={order}
              orderBy="lastName:asc,firstName"
              toggleSort={toggleSort}
              fetchAll={fetchAll}
              defaultSort="lastName:asc,firstName"
              idField="_id"
            />
          </MDBox>
          {genericModalOpen ? (
            <ApplicantActionModal
              open={genericModalOpen}
              setOpen={setGenericModalOpen}
              modalInfo={{ type: "applicantmail", data: currentMember }}
              setToastAlert={setToastAlert}
              currentApplicant={currentMember}
              messageContext="Applicant"
              attachmentContext="Applicant"
            />
          ) : null}
        </MDBox>

        {openReportMessageModal && (
          <ReportModal
            reportingType="applicants"
            message="Coming Soon!"
            isOpen={openReportMessageModal}
            toggleOpen={setOpenReportMessageModal}
            handleGenerateReport={handleGenerateReport}
            chartTableSrc={chartTableSrc}
            chartSrc={chartSrc}
            title="Partner Applicant Report"
            handleExportReport={handleExportReport}
            handleSaveReport={handleSaveReport}
            isLoadingExport={isLoadingExport}
            isLoadingSave={isLoadingSave}
            reportModalValues={reportModalValues}
          />
        )}
        {membersModalOpen && (
          <MembersModal
            currentApplicant={currentMember}
            open={membersModalOpen}
            setDropdowns={setDropdowns}
            setOpen={setMembersModalOpen}
            handleSubmit={handleSubmit}
            Controller={Controller}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            reset={reset}
            errors={errors}
            currentPartner={currentPartner}
            dirtyFields={dirtyFields}
            getValues={getValues}
            setError={setError}
          />
        )}
        <ApplicantNotesList
          currentApplicant={currentMember}
          open={memberNotesListOpen}
          setOpen={setMemberNotesListOpen}
          onSuccess={handleNotesSuccess}
          isCreateTaskAllowed
          setTaskModalOpen={setTaskModalOpen}
        />
      </Card>
      <TaskDetailsModal
        mode="create"
        setTaskModalOpen={setTaskModalOpen}
        taskModalOpen={taskModalOpen}
        currentTask={null}
        setToastAlert={setToastAlert}
        currentApplicant={currentMember}
        parent="Notes"
      />
    </>
  );
};

// Setting default values for the props
PartnerApplicantsTable.defaultProps = {
  // currentPartner: {},
  fetchAll: false,
  title: "Applicants",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
PartnerApplicantsTable.propTypes = {
  // currentPartner: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default PartnerApplicantsTable;
