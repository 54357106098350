import WorkIcon from "@mui/icons-material/Work";
import { IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useMutation } from "react-query";

import CustomMenu from "components/CustomizedMui/CustomMenu";
import { useAppContextController } from "context/AppContext";
import { APPLICANT_JOB_STATUS_ENUM, JOB_STATUS_ENUM } from "utils/constants/applicant";
import ConfirmationModal from "components/ConfirmationModal";
import updateApplicantJobStatus from "layouts/pages/applicants/actions/updateApplicantJobStatus";
import ApplicantJobStatusFilledModal from "components/ApplicantJobStatusButton/ApplicantJobStatusFilledModal";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import { useInvalidateCheckForHelmAllowed } from "hooks/useInvalidateCheckForHelmAllowed";

const statusColor = {
  None: "dark",
  "On Assignment": "success",
  Pending: "warning",
  Declined: "error",
  Terminated: "error",
  Completed: "primary",
};

const JobStatusButton = ({ status = "None", job, viewOnly = false, onUpdate, refresh }) => {
  const { currentApplicant, setCurrentApplicant, currentLoggedUser, company } =
    useAppContextController();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isConfirmationOpen, toggleConfirmationModal] = useState(false);
  const [isJobStatusFilledModalOpen, toggleJobStatusFilledModal] = useState(false);
  const [notesModalOpen, setNoteModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const [currentStatusForNote, setCurrentStatusForNote] = useState("");
  const isCompany = company?.companyType === "Company";

  const isJobFull = job.isJobFull ?? false;
  const { invalidateCheckForHelmAllowed } = useInvalidateCheckForHelmAllowed();

  const changeStatusMutation = useMutation(updateApplicantJobStatus, {
    onSuccess: (_, { data }) => {
      if (refresh) refresh(data?.jobSlug, data?.status);
    },
    onError: (error) => {
      enqueueSnackbar(error?.response?.data?.message ?? error.message, { variant: "error" });
    },
    mutationKey: "jobStatusMutation",
  });

  const handleUpdate = useCallback(
    (newStatus, startDate, jobNote) => {
      const { jobSlug } = job;

      const data = {
        applicantId: currentApplicant._id,
        data: {
          status: newStatus,
          jobSlug,
        },
      };

      // The fill position modal returns 'Filled', which isn't a valid job status
      if (newStatus === APPLICANT_JOB_STATUS_ENUM.FILLED) {
        data.data.status = JOB_STATUS_ENUM.ON_ASSIGNMENT;
        if (startDate) {
          data.data.startDate = startDate;
        }
      }

      if (jobNote) {
        data.data.jobNote = jobNote;
      }

      changeStatusMutation.mutate(data, {
        onSuccess: async () => {
          enqueueSnackbar(
            `${currentApplicant.firstName} ${currentApplicant.lastName} has been set to: ${data.data.status} for ${job.jobSlug}`,
            {
              variant:
                statusColor[data.data.status] === "primary"
                  ? "success"
                  : statusColor[data.data.status],
            }
          );
          // if (job?._id && job?.jobSlug) {
          //   createActivityMutation({
          //     action: "job Status",
          //     description: `Set job Status for ${job.jobSlug} to ${data.data.status}`,
          //     venueId: job._id,
          //     applicantId: currentApplicant?._id,
          //   });
          // }
          if (currentApplicant?._id && isCompany) {
            await invalidateCheckForHelmAllowed(
              currentApplicant?.status === "Employee",
              currentApplicant._id
            );
          }
          onUpdate?.();
          toggleConfirmationModal(false);
        },
      });
    },
    [currentApplicant, job, changeStatusMutation, enqueueSnackbar, onUpdate]
  );

  const handleUpdateStageWithNote = (newStatus) => {
    setCurrentStatusForNote(newStatus);
    setNoteModalOpen(true);
  };

  const saveNote = (noteRec) => {
    handleUpdate(currentStatusForNote, null, noteRec.text);
  };

  const options = [
    {
      text: "None",
      icon: <WorkIcon fontSize="medium" color={statusColor.None} />,
      handleClick: () => toggleConfirmationModal(true),
      disabled: JOB_STATUS_ENUM.NONE === status,
    },

    {
      text: "On Assignment",
      icon: <WorkIcon fontSize="medium" color={statusColor[JOB_STATUS_ENUM.ON_ASSIGNMENT]} />,
      handleClick: () => toggleJobStatusFilledModal(true),
      disabled: isJobFull || JOB_STATUS_ENUM.ON_ASSIGNMENT === status,
      tooltip: isJobFull && JOB_STATUS_ENUM.ON_ASSIGNMENT !== status ? "No open positions" : null,
    },
    {
      text: "Pending",
      icon: <WorkIcon fontSize="medium" color={statusColor[JOB_STATUS_ENUM.PENDING]} />,
      handleClick: () => handleUpdateStageWithNote(JOB_STATUS_ENUM.PENDING),
      disabled: JOB_STATUS_ENUM.PENDING === status,
    },
    {
      text: "Declined",
      icon: <WorkIcon fontSize="medium" color={statusColor[JOB_STATUS_ENUM.DECLINE]} />,
      handleClick: () => handleUpdateStageWithNote(JOB_STATUS_ENUM.DECLINE),
      disabled: JOB_STATUS_ENUM.DECLINE === status,
    },
    {
      text: "Terminated",
      icon: <WorkIcon fontSize="medium" color={statusColor[JOB_STATUS_ENUM.TERMINATED]} />,
      handleClick: () => handleUpdateStageWithNote(JOB_STATUS_ENUM.TERMINATED),
      disabled: JOB_STATUS_ENUM.TERMINATED === status,
    },
    {
      text: "Completed",
      icon: <WorkIcon fontSize="medium" color={statusColor[JOB_STATUS_ENUM.COMPLETED]} />,
      handleClick: () => handleUpdateStageWithNote(JOB_STATUS_ENUM.COMPLETED),
      disabled: JOB_STATUS_ENUM.COMPLETED === status,
    },
  ];

  return (
    <>
      <Tooltip
        title={`${currentApplicant?.firstName} ${currentApplicant?.lastName} is ${
          status === "None" ? "Not Associated" : status
        }`}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (!viewOnly) {
              setAnchorEl(e.currentTarget);
            }
          }}
          sx={{ ...(viewOnly && { cursor: "default" }) }}
        >
          <WorkIcon fontSize="large" color={statusColor[status]} />
        </IconButton>
      </Tooltip>
      <CustomMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} options={options} />
      <ApplicantJobStatusFilledModal
        open={isJobStatusFilledModalOpen}
        setOpen={toggleJobStatusFilledModal}
        applicantName={`${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
        handleUpdateStage={handleUpdate}
      />
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => toggleConfirmationModal(false)}
        title={`Are you sure you want to remove ${currentApplicant?.firstName} ${currentApplicant?.lastName} from ${job.title}?`}
        onConfirm={(e) => {
          e.stopPropagation();
          handleUpdate(JOB_STATUS_ENUM.NONE)();
          toggleConfirmationModal(false);
        }}
      />
      {notesModalOpen && (
        <AddANoteModal
          currentApplicant={currentApplicant}
          open={notesModalOpen}
          toastAlert={toastAlert}
          setToastAlert={setToastAlert}
          setOpen={setNoteModalOpen}
          saveNote={saveNote}
          showType={false}
          defaultValue={`${currentApplicant?.firstName} ${currentApplicant?.lastName} status changed to ${currentStatusForNote} for job ${job?.jobSlug}`}
          onExtraAction={() => handleUpdate(currentStatusForNote)}
          extraActionLabel="Change Status Only"
        />
      )}
    </>
  );
};

export default JobStatusButton;
