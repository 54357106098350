import { useEffect, useState } from "react";
/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";
import updateJob from "layouts/pages/jobs/actions/updateJob";
import CustomCard from "components/CustomCard";
import TagList from "components/TagList";
import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";
import { getDirtyFields } from "utils/helpers/formHelpers";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import { FolderCopy } from "@mui/icons-material";
import MDEditor from "components/MDEditor";
import MDBox from "components/MDBox";
import { Box } from "@mui/material";
import PanelActions from "components/PanelActions";
import MDTypography from "components/MDTypography";

const useStyle = makeStyles({
  editor: {
    height: 275,
  },
});

const JobsContent = () => {
  const { currentJob, setCurrentJob, isMobile } = useAppContextController();

  const classes = useStyle();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: updateJobMutation } = useMutation(updateJob);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isDirty, dirtyFields },
  } = useForm();

  const handleUpdateJob = (values, method = "update" /* onSuccess */) => {
    const modifiedFields = getDirtyFields(values, dirtyFields);
    updateJobMutation(
      { _id: currentJob?._id, data: { ...modifiedFields } },
      {
        onSuccess: async (res) => {
          setCurrentJob({ ...currentJob, ...values });
          await queryClient.invalidateQueries("jobs");
          // onSuccess(method, values?.content);
          enqueueSnackbar("Job has been updated.", { variant: "success" });
        },
        onError: (err) => enqueueSnackbar(`An error occured: ${err}`, { variant: "error" }),
      }
    );
  };

  const handleCancelChanges = () => {
    reset(currentJob);
    enqueueSnackbar("Changes have been abandoned!", { variant: "success" });
  };

  useEffect(() => {
    reset(currentJob);
  }, [currentJob]);

  return (
    <form onSubmit={handleSubmit(handleUpdateJob)}>
      <CustomCard
        icon={<FolderCopy color="white" />}
        cardTitle={`Job Content: ${currentJob?.name || currentJob?.title}`}
        cardActions={isDirty && <PanelActions onCancel={handleCancelChanges} />}
        {...customPanelCard(isMobile, "primary")}
      >
        <Box sx={{ ...styles.container, mt: 2, height: 629 }}>
          <MDBox mb={10}>
            <Controller
              name="description"
              control={control}
              render={({ field: { value, onChange, ...props } }) => (
                <MDEditor
                  className={classes.editor}
                  theme="snow"
                  onChange={(text) => {
                    onChange(text);
                  }}
                  value={value || ""}
                  placeholder="Write Content"
                  {...props}
                />
              )}
            />
          </MDBox>
          <Box>
            <Controller
              name="tags"
              control={control}
              defaultValue={watch("tags")}
              render={({ field }) => (
                <TagList
                  {...field}
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                  currentComponent={currentJob}
                  label="Must-have skills"
                />
              )}
            />
            {errors?.invalidTag && (
              <MDTypography color="error">{errors?.invalidTag?.message}</MDTypography>
            )}
          </Box>
        </Box>
      </CustomCard>
    </form>
  );
};

export default JobsContent;
