import * as yup from "yup";
import moment from "moment";

const timeValidation = yup.object().shape({
  start: yup
    .string()
    .nullable()
    .test(
      "start-required",
      "Start time is required when there is end time",
      function isStartRequired() {
        const { start, end } = this.parent;
        return !(end && !start);
      }
    ),
  end: yup
    .string()
    .nullable()
    .test("is-after-start", "End time must be after Start time", function isAfterStart(value) {
      const { start } = this.parent;
      return start && value ? moment(value).isAfter(moment(start)) : true;
    })
    .test(
      "end-required",
      "End time is required when there is start time",
      function isEndRequired() {
        const { start, end } = this.parent;
        return !(start && !end);
      }
    ),
});

export const shiftsInfoSchema = yup.object().shape({
  location: yup.object().required("Location is required").typeError("Location is required"),
  startDate: yup.string().required("Start Date is required"),
  endDate: yup
    .string()
    .required("End Date is required")
    .test(
      "is-after-start-date",
      "End Date must be same or after Start Date",
      function isAfterStartDate(value) {
        const { startDate } = this.parent;
        return moment(value).isSameOrAfter(moment(startDate));
      }
    ),
  shifts: yup.array().of(
    yup.object().shape({
      shiftName: yup.string().required("Shift Name is required"),
      payRate: yup
        .number()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .nullable()
        .required("Pay Rate is required")
        .typeError("Pay Rate must be a number"),

      billRate: yup
        .number()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .nullable()
        .required("Bill Rate is required")
        .typeError("Bill Rate must be a number"),
      defaultSchedule: yup.object().shape({
        monday: timeValidation,
        tuesday: timeValidation,
        wednesday: timeValidation,
        thursday: timeValidation,
        friday: timeValidation,
        saturday: timeValidation,
        sunday: timeValidation,
      })
    }))
});
