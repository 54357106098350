import { Box, Paper, Stack, styled, Table, TableCell, TableRow, Typography } from "@mui/material";
import GenericModal from "components/GenericModal";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {},
}));

const RecognitionDetailsModal = ({ recognition, getFilePreview, open, setOpen }) => {
  const data = useMemo(() => {
    return Object.entries(recognition ?? {});
  }, [recognition]);

  const header = (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box sx={{ mt: 2, mb: 1, ml: 2 }}>
        <Typography variant="h5" color="dark">
          Recognition Details
        </Typography>
      </Box>
      <IconButton onClick={() => setOpen(false)}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
  const modalBody = (
    <Stack display="flex" direction="row" alignItems="center">
      <Box display="flex" flex={1}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {data.map(([key, value]) => (
                <StyledTableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box display="flex" flex={1} alignItems="center" justifyContent="center">
        {getFilePreview(450)}
      </Box>
    </Stack>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        header={header}
        body={modalBody}
        // buttons={modalButtons}
        width="60%"
        overflow="auto"
      />
    </>
  );
};

export default RecognitionDetailsModal;
