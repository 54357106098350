import * as yup from "yup";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";

export const createApplyPageSchema = (
  modalFlag,
  setModalFlag,
  emailVerified,
  setMailAlreadyUsed
) => {
  return yup.object().shape({
    firstName: yup
      .string()
      .required("First name is required")
      .matches(/^[A-Za-z\s.,-]*$/, "First Name contains invalid characters."),
    lastName: yup
      .string()
      .required("Last name is required")
      .matches(/^[A-Za-z\s.,-]*$/, "Last Name contains invalid characters."),
    email: yup
      .string()
      .default("")
      // eslint-disable-next-line
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid Email Address."
      )
      .test({
        name: "repeated email",
        async test(value, ctx) {
          if (
            value.match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
          ) {
            const filteredApplicants = await fetchApplicants({
              filters: { email: value },
              fetchAll: true,
              outsideMode: "protected",
              projectedFields: "email",
            });
            if (value && filteredApplicants !== "" && !modalFlag && !emailVerified) {
              setMailAlreadyUsed(true);
              setModalFlag(true);
            } else if (value && filteredApplicants === "" && !modalFlag && !emailVerified) {
              setMailAlreadyUsed(false);
              setModalFlag(true);
            }
            return true;
          }
          return true;
        },
      })
      .required("Email is required"),
    phone: yup
      .string()
      .default("")
      .test({
        name: "test-phone-length",
        test: (value, ctx) => {
          const trimValueLength = value.replace(/\D/g, "").length;

          if (trimValueLength === 10) {
            return true;
          }
          return ctx.createError({ message: "Phone number must be 10 digits" });
        },
      })
      .required("Phone is required"),
    zip: yup
      .string()
      .default("")
      .test({
        name: "test-zip-format",
        test: (value, ctx) => {
          const parsed = value.replace(/\D/g, "");
          if (parsed.length === 5 || parsed.length === 9) {
            return true;
          }
          return ctx.createError({ message: "Zip should only be a 5 or 9 digit format" });
        },
      })
      .required("Zip code is required"),
    referralSource: yup
      .string()
      .matches(/^[A-Za-z\s]*$/, "Referral Source contains invalid characters."),
  });
};
