import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Box, Card, Grid, Icon, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Searchbar from "components/Searchbar";
import VenueIcon from "components/VenueIcon";
import VenueStatusButton from "components/VenueStatusButton";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import ApplicantVenueStatus from "layouts/pages/events/components/ApplicantVenueStatus";
import VenuesTableActions from "layouts/pages/venues/components/VenuesTableActions";
import fetchUserVenues from "layouts/pages/venues/actions/fetchUserVenues";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import fetchVenuesByZipCode from "layouts/pages/venues/actions/fetchVenuesByZipcode";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import useSort from "utils/useSort";
import useSessionAuth from "hooks/useSessionAuth";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import ReportModal from "components/ReportModal";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import { generateVenueChartReport, generateVenueTableReport } from "api/reports/venueReport";
import { exportReport } from "api/reports/exportReport";
import { saveReport } from "api/reports/saveReport";
import moment from "moment";
import fetchModuleReports from "api/reports/fetchModuleReports";
import useHandleBackButtonSubGrid from "hooks/useHandleBackButtonSubGrid";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const VenuesTable = ({
  slug,
  fetchAll = true,
  setNavigateUrl,
  title = "Venues Table",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  showVenueStatus = false,
  currentApplicant = null,
  setOpen,
  setVenuePreview,
  refreshVenues,
  setToastAlert,
  parentPage,
  parentNavigateWithBack,
}) => {
  const queryClient = useQueryClient();
  const classes = useStyle();
  const navigate = useNavigate();
  const {
    venues: venueCache,
    setVenues,
    setCurrentVenue,
    currentVenue,
    userType,
    currentLoggedUser,
    company,
  } = useAppContextController();

  const { action } = useParams();
  // Pagination state
  const [page, setPage] = useState(parentPage ?? 1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  // const [prevPage, setPrevPage] = useState(1)
  const { order, orderBy, toggleSort } = useSort();
  const [userApplicant, setUserApplicant] = useState(null);
  const [userVenues, setUserVenues] = useState(null);
  const [refetchApplicant, setRefetchApplicant] = useState(false);
  const [lockedVenues, setLockedVenues] = useState([]);
  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);

  // Handle back button from parent screens
  const { navigateWithBack } = useHandleBackButtonSubGrid({
    parentPage,
    setPage,
    page,
    filters,
    navigateFunc: setNavigateUrl,
    navigateWithBackFunc: parentNavigateWithBack,
  });

  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);

  const { mutateAsync: createVenueTableReport } = useMutation(generateVenueTableReport);
  const { mutateAsync: createVenueChartReport } = useMutation(generateVenueChartReport);
  const { mutateAsync: exportVenueReport, isLoading: isLoadingExport } = useMutation(exportReport);
  const { mutateAsync: saveVenueReport, isLoading: isLoadingSave } = useMutation(saveReport);

  let imageUrl;
  if (company) imageUrl = company.imageUrl;
  const options = fetchAll ? { fetchAll, imageUrl } : { page, limit, order, orderBy };
  const allOptions =
    userType !== "User"
      ? { ...options, filters, setVenues, imageUrlBase: company?.imageUrl }
      : {
          ...options,
          filters: { slug: filters?.slug || userVenues },
          imageUrlBase: company?.imageUrl,
        };

  if (userType === "Client" || userType === "Event Admin") {
    const clientOrgSlugs = currentLoggedUser?.clientOrgs?.map((item) => item.slug);
    allOptions.filters = { slug: clientOrgSlugs?.join(";") };
  }

  const {
    data: venues,
    isLoading,
    refetch: refetchVenues,
  } = useQuery(
    ["myvenues", allOptions],
    () => (userType === "User" ? fetchUserVenues(allOptions) : fetchVenues(allOptions)),
    {
      // The select function will receive the data returned by the query function
      select: (data) => {
        if (userType === "User" && lockedVenues?.length) {
          const returnObj = {
            ...data,
            data: data.data?.filter(
              (item) => !lockedVenues.includes(item.slug) && item.status === "Active"
            ),
          };
          return returnObj;
        }
        const returnObj2 = {
          ...data,
          data: data.data?.filter((item) =>
            ["Active", "Inactive", "Prospect"].includes(item.status)
          ),
        };
        return returnObj2; // data.data.filter((item) => !lockedVenues.includes(item.slug));
      },
    }
  );

  const setPreviewOpen = (venue, e) => {
    e.stopPropagation();
    setVenuePreview(venue);
    setOpen(true);
  };

  useEffect(() => {
    // alert('firing!')
    refetchVenues();
  }, [refreshVenues]);

  const { logoutAuth0User } = useSessionAuth();

  const refetch = () => {
    setRefetchApplicant((prev) => !prev);
  };
  useEffect(() => {
    const fetchUserApplicant = async (id) => {
      const app = await fetchSingleApplicant({ applicantId: id });
      if (app?.data) {
        setUserApplicant(app.data);
        const locked = app?.data?.venues
          ?.filter((item) => item.venueSlug && item.status === "Locked")
          .map((item) => item.venueSlug);
        setLockedVenues(locked);
      }
    };
    if (userType === "User" && currentLoggedUser) {
      try {
        fetchUserApplicant(currentLoggedUser.applicantId);
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  }, [currentLoggedUser?.applicantId, userType, refetchApplicant]);

  useEffect(() => {
    if (userApplicant?.venues?.length) {
      const vens = userApplicant.venues
        .filter((item) => item.status !== "Locked")
        .map((item) => item.venueSlug)
        .join(";");
      setUserVenues(vens);
    }
  }, [userApplicant]);

  const localVenues = venueCache ? Object.entries(venueCache).map((item) => item[1]) : [];
  useEffect(() => {
    if (!isLoading && localVenues?.length && slug) {
      const ndx = localVenues.findIndex((item) => item.slug === slug);
      if (ndx > -1) {
        setCurrentVenue(localVenues[ndx]);
      }
    }
  }, [isLoading, slug]);

  useEffect(() => {
    setPage(1);
  }, [action, filters]);

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const handleLoadInitialVenueReport = useCallback(async () => {
    if (openReportMessageModal) {
      try {
        const response = await fetchModuleReports("venues");

        const latestCriteria = getLatestCriteria(
          response?.tableReportData,
          response?.chartReportData,
          defaultReportModalValues
        );
        setReportModalValues(latestCriteria);

        // Set table details
        if (response?.tableReportData) {
          setChartTableSrc(response.tableReportData.queryDetails.iframeSrc);
          setTableQueryDetails(response.tableReportData.queryDetails);
          setTableReportData(response.tableReportData);
        }

        // Set chart details
        if (response?.chartReportData) {
          setChartSrc(response.chartReportData.queryDetails.iframeSrc);
          setChartQueryDetails(response.chartReportData.queryDetails);
          setChartReportData(response.chartReportData);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReportMessageModal]);

  useEffect(() => {
    handleLoadInitialVenueReport();
  }, [handleLoadInitialVenueReport]);

  const handleExportReport = useCallback(
    async (reportType, fileType) => {
      try {
        const payload = {
          queryId: reportType === "table" ? tableQueryDetails?.id : chartQueryDetails?.id,
          fileType,
        };

        await exportVenueReport(payload, {
          onSuccess: async (response) => {
            setToastAlert({
              isShow: true,
              message: `Venue ${reportType === "table" ? "Table" : "Chart"} ${
                fileType === "csv" ? " CSV" : " Excel"
              } Report has been successfully exported!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error exporting report:", error);
      }
    },
    [chartQueryDetails?.id, exportVenueReport, setToastAlert, tableQueryDetails?.id]
  );

  const handleGenerateReport = async (values) => {
    if (values?.formType === "table") {
      let reportPayload = {};

      if (values?.tableColumns?.length > 0) {
        reportPayload = { ...reportPayload, columns: values?.tableColumns };
      }

      if (values?.sortBy) {
        reportPayload = { ...reportPayload, sortBy: values.sortBy };
      }

      if (values?.order) {
        reportPayload = { ...reportPayload, order: values.order };
      }

      if (values?.dateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.startDate,
            end: values?.endDate,
            field: values?.dateField,
            range: values?.dateRange,
          },
        };
      }

      if (values?.filters) {
        reportPayload = {
          ...reportPayload,
          filters: values?.filters,
        };
      }

      if (values?.filterCards) {
        reportPayload = {
          ...reportPayload,
          filterCards: values?.filterCards,
        };
      }

      if (values?.subFilterCards) {
        reportPayload = {
          ...reportPayload,
          subFilterCards: values?.subFilterCards,
        };
      }

      try {
        await createVenueTableReport(reportPayload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartTableSrc(response?.iframe_url);
              setTableQueryDetails(response?.queryDetails);
              setTableReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: "Venue Table Report has been successfully generated!",
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error generating table report:", error);
      }
    } else {
      const reportPayload = {
        xAxis: values?.xAxis,
        yAxis: values?.yAxis,
        groupBy: values?.groupBy,
        chartType: values?.chartType,
      };

      try {
        await createVenueChartReport(reportPayload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: "Venue Chart Report has been successfully generated!",
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error generating chart report:", error);
      }
    }
  };

  const handleSaveVenueReport = useCallback(
    async (values) => {
      try {
        let payload = {
          criteria: values,
        };

        if (values?.formType === "table") {
          payload = { ...payload, tableReportData, formType: "table" };
        } else if (values?.formType === "chart") {
          payload = { ...payload, chartReportData, formType: "chart" };
        }

        await saveVenueReport(payload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: `Venue ${
                values?.formType === "table" ? "Table" : "Chart"
              } Report has been successfully saved!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error saving venue report:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartReportData, saveVenueReport, tableReportData]
  );

  const columns = [
    {
      title: "Logo",
      field: "logoUrl",
      imageWidth: "50",
      imageHeight: "50",
      customCell: (logoUrl, field, row) => (
        <VenueIcon
          logoUrl={logoUrl}
          slug={row.slug}
          name={row.name}
          imageWidth="50"
          imageHeight="50"
          goToEnabled={false}
          setOpenVenueModal={() => {
            setVenuePreview(row);
            setOpen(true);
          }}
        />
      ),
    },
    ...(showVenueStatus && currentApplicant
      ? [
          {
            title: "Venue",
            field: "slug",
            customCell: (vslug, field, row) => (
              <VenueStatusButton
                status={getApplicantVenueStatus(currentApplicant, vslug)}
                venue={row}
                onUpdate={() => refetchVenues()}
              />
            ),
          },
        ]
      : []),
    ...(userType === "User" && userApplicant
      ? [
          {
            title: "Status",
            field: "slug",
            customCell: (vslug, field, row) => (
              <>
                <VenueStatusButton
                  status={getApplicantVenueStatus(userApplicant, vslug)}
                  venue={row}
                  viewOnly
                />
              </>
            ),
          },
        ]
      : []),
    {
      title: "Venue ID",
      field: "slug",
      customCell: (thisSlug) => <span>{thisSlug?.toUpperCase()}</span>,
    },
    { title: "Venue Name", field: "name" },
    // { title: "Venue URL", field: "venueUrl" },
    { title: "City", field: "city" },
    { title: "State", field: "state" },
    {
      title: "Actions",
      noSort: true,
      field: "slug",
      customCell: (thisSlug, field, row) => (
        <VenuesTableActions
          slug={thisSlug}
          row={row}
          setNavigateUrl={navigateWithBack}
          setFilters={setFilters}
          setPreviewOpen={setPreviewOpen}
        />
      ),
    },
  ];

  const userColumns = [
    ...(userType === "User" && userApplicant
      ? [
          {
            title: "Status",
            field: "slug",
            customCell: (vslug) => {
              return (
                <ApplicantVenueStatus
                  id={userApplicant?._id}
                  venue={vslug}
                  slugField="venueSlug"
                  row={userApplicant}
                  // invalidQuery={["venues", allOptions]}
                  blockUpdate
                  mode="Check"
                  setRequery={refetch}
                />
              );
            },
            width: "15%",
          },
        ]
      : []),
    {
      title: "Logo",
      field: "logoUrl",
      imageWidth: "50",
      imageHeight: "50",
      customCell: (logoUrl, row) =>
        row ? (
          <VenueIcon
            logoUrl={logoUrl}
            slug={row?.slug}
            name={row?.name}
            imageWidth="50"
            imageHeight="50"
          />
        ) : null,
      width: "15%",
    },
    {
      title: "Venue Name",
      field: "name",
      customCell: (name) => `${name.length > 20 ? name.substring(0, 20) : name}...`,
      width: "40%",
    },
    {
      title: "City/State",
      field: "city",
      customCell: (city, row) => `${city}, ${row?.state}`,
      width: "30%",
    },
  ];

  const handleRowClick = (venue, e) => {
    setCurrentVenue(venue);
    setNavigateUrl(`/venues/${venue.slug}`);
    e.stopPropagation();
  };

  const addNew = (e) => {
    if (!currentVenue) {
      setCurrentVenue({});
    }
    navigate("/venues/create");
    e.stopPropagation();
  };

  useEffect(() => {
    if (!openReportMessageModal) {
      setChartTableSrc("");
      setChartSrc("");
    }
  }, [openReportMessageModal]);

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1} pr={2}>
        <Grid item>
          <FlexWrapper mt={0} mb={0} rowGap="20px" flexDirection={{ xs: "column", sm: "row" }}>
            <Box
              display="flex"
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  alignSelf: "flex-start",
                },
              })}
            >
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="info"
                color="white"
                borderRadius="xl"
                ml={3}
                mt={-6}
              >
                <Icon fontSize="large">stadium</Icon>
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                {title}
              </MDTypography>
            </Box>
            <MDBox ml={3}>
              {userType !== "User" &&
                userType !== "Client" &&
                userType !== "Event Admin" &&
                showFiltersList && (
                  <Grid item>
                    <FiltersList filters={filters} setFilters={setFilters} />
                  </Grid>
                )}
            </MDBox>
          </FlexWrapper>
        </Grid>
        <Grid
          item
          display="flex"
          alignItems="center"
          sx={{ maxWidth: "600px" }}
          xs={12}
          sm={8}
          lg={5}
        >
          <FlexWrapper
            flexWrap="nowrap"
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ mx: "15px", rowGap: "10px" }}
          >
            {showSearchBar && userType !== "Client" && userType !== "Event Admin" && (
              <Searchbar
                fetch={userType === "User" ? fetchUserVenues : fetchVenues}
                fetchAll={false}
                placeholder="Search Venue"
                columns={userColumns}
                queryCharacterLimit={2}
                resultsLimit={15}
                setFilters={setFilters}
                setPage={setPage}
                onRowClick={(item) => {
                  if (userType !== "User") navigate(`/venues/${item.slug}/action/venueinfo`);
                }}
                searchBy={["slug", "name", "city"]}
                filterBy="slug"
                idField="slug"
                filterData={
                  userType === "User"
                    ? (item) => {
                        return item.status === "Active" && !lockedVenues.includes(item.slug);
                      }
                    : undefined
                }
              />
            )}
            {userType === "User" && showSearchBar && (
              <Searchbar
                fetch={fetchVenuesByZipCode}
                fetchAll={false}
                placeholder="My Zipcode"
                columns={userColumns}
                queryCharacterLimit={5}
                resultsLimit={12}
                scrollLimit={50}
                setFilters={setFilters}
                setPage={setPage}
                searchBy={["zip"]}
                useRestParams
                filterBy="slug"
                autoCenter={false}
                filterData={(item) => {
                  return item.status === "Active" && !lockedVenues.includes(item.slug);
                }}
              />
            )}
          </FlexWrapper>
          {userType !== "User" && userType !== "Client" && userType !== "Event Admin" && (
            <MDBox>
              <IconButton className={classes.addButton} color="info" onClick={(e) => addNew(e)}>
                <AddCircleRoundedIcon />
              </IconButton>
            </MDBox>
          )}

          {userType !== "User" && userType !== "Client" && userType !== "Event Admin" && (
            <Tooltip title="Create Report">
              <IconButton color="info" onClick={() => setOpenReportMessageModal(true)}>
                <ViewComfyIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={venues}
          selected={currentVenue}
          onRowClick={
            userType === "Client" || userType === "Event Admin"
              ? setPreviewOpen
              : (row, e) => (userType !== "User" ? handleRowClick(row, e) : () => {})
          }
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          isLoading={isLoading} // Extract isLoading from useQuery then pass here (required)
          defaultSort="name"
          greybar
          divider
        />
      </MDBox>
      {pageLimitConfirmationModal}

      {openReportMessageModal && (
        <ReportModal
          reportingType="venues"
          message="Coming Soon!"
          isOpen={openReportMessageModal}
          toggleOpen={setOpenReportMessageModal}
          handleGenerateReport={handleGenerateReport}
          chartTableSrc={chartTableSrc}
          title="Venue Report"
          chartSrc={chartSrc}
          filterCardList={["Active", "Prospect", "Inactive", "MyVenues", "All"]}
          subFilterCardList={[
            "All Prospects",
            "Prospect",
            "Qualify",
            "Proposal",
            "Closed/Win",
            "Closed/Lost",
          ]}
          handleExportReport={handleExportReport}
          handleSaveReport={handleSaveVenueReport}
          isLoadingExport={isLoadingExport}
          isLoadingSave={isLoadingSave}
          reportModalValues={reportModalValues}
        />
      )}
    </Card>
  );
};

// Setting default values for the props
VenuesTable.defaultProps = {
  // currentVenue: {},
  fetchAll: true,
  setNavigateUrl: () => {},
  title: "Venues",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
VenuesTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  setNavigateUrl: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default VenuesTable;
