import { Controller } from "react-hook-form";
import { Button } from "@mui/material";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";

const InputFlexWrapper = ({ children, ...props }) => (
  <FlexWrapper maxWidth={{ lg: "47%", sm: "100%" }} marginBottom={2.5} {...props}>
    {children}
  </FlexWrapper>
);

const FormTextEditor = ({
  title,
  name,
  onClick,
  control, // optional
  text = "", // only if no control
  setText = () => {}, // only if no control
  height = "12.025rem",
  ...rest
}) => {
  return (
    <InputFlexWrapper
      flexDirection={{ xs: "column", md: "row" }}
      flexWrap="nowrap"
      alignItems="flex-start"
      maxWidth="100%"
      {...rest}
    >
      <FlexWrapper
        flexWrap="nowrap"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        sx={{
          "& div:last-child": {
            lg: {
              height: "100%",
              width: "100%",
            },
            "& .ql-editor": {
              minHeight: height,
              maxHeight: height,
              overflow: "hidden",
              overflowY: "auto",
              overflowX: "auto",
            },
          },
        }}
      >
        <FlexWrapper justifyContent="space-between">
          <MDTypography component="label" variant="caption" color="secondary" fontWeight="regular">
            {title}
          </MDTypography>
          {onClick &&     
          <Button onClick={onClick} sx={{ padding: 0 }}>
            Preview
          </Button> }
      
        </FlexWrapper>
        {control ? (
          <Controller
            name={name}
            control={control}
            defaultValue="<p></p>"
            render={({ field: { value, onChange, ...props } }) => (
              <MDEditor
                flex="1 0 auto"
                onChange={(val) => onChange(val)}
                value={value}
                placeholder={`Write ${title}`}
                {...props}
              />
            )}
          />
        ) : (
          <MDEditor
            flex="1 0 auto"
            onChange={(val) => setText(val)}
            value={text}
            placeholder={`Write ${title}`}
          />
        )}
      </FlexWrapper>
    </InputFlexWrapper>
  );
};

export default FormTextEditor;
