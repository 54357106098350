import { useEffect, useState } from "react";
import { SnackbarProvider } from "notistack";
import { useAppContextController } from "context/AppContext";
import useCompanyCache, { useOutsideCompanyCache } from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";

import ContainerWrapper from "components/Wrapper/ContainerWrapper";
import { NewApplicantContextProvider } from "context/NewApplicantContext";
import FormContainer from "../FormContainer";
import MessageSection from "../MessageSection";
import NewApplicantHeader from "../NewApplicantHeader";

const NewOnboarding = () => {
    const { venues, setVenues, company, setCompany, setCompanyType, user } =
        useAppContextController();
    const { isLoadingCompany, refetchCompany } = useOutsideCompanyCache({
        company,
        setCompany,
        setCompanyType,
        outsideMode: "protected",
    });
    const { isLoadingVenues, refetchVenues } = useVenueCache({
        venues,
        setVenues,
        company,
        outsideMode: "protected",
    });

    const [isAvailable, setIsAvailable] = useState(true);

    useEffect(() => {
        if (!venues || !Object.keys(venues)?.length) {
            refetchVenues();
        }
    }, []);

    return (
        <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            key="top-center"
        >
            <NewApplicantContextProvider outsideMode={user ? "" : "protected"}>
                <ContainerWrapper sx={{ px: "20px", pt: "15px" }} position="relative">
                    <NewApplicantHeader isAvailable={isAvailable} setIsAvailable={setIsAvailable} />
                    <MessageSection isAvailable={isAvailable} />
                    {isAvailable && <FormContainer />}
                </ContainerWrapper>
            </NewApplicantContextProvider>
        </SnackbarProvider>
    );
};

export default NewOnboarding;