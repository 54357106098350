import { Grid, Icon } from "@mui/material";
// import { useSnackbar } from "notistack";

import { useAppContextController } from "context/AppContext";
import useCompanyStore from "stores/useCompanyStore";

import CustomCard from "components/CustomCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import companyAttachmentTypes from "../../../../../assets/companyAttachmentTypes.json";
import Attachments from "../CompanyAttachment";
import UploadFileModal from "../FileUploadDropzone";
import ImageUploadDropzone from "../ImageUploadDropzone";

const CompanyMedia = ({ sourceComponent, refetchCompany }) => {
  const { company: primaryCompany, setCompany: setPrimaryCompany } = useAppContextController();
  //   const enqueueSnackbar = useSnackbar();
  const { currentCompany, setCurrentCompany, companyFormState } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
    companyFormState: state.companyFormState,
  }));

  const company = sourceComponent === "Customer" ? currentCompany : primaryCompany;
  const setCompany = sourceComponent === "Customer" ? setCurrentCompany : setPrimaryCompany;

  return (
    <CustomCard
      icon={
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="info"
          color="white"
          borderRadius="xl"
        >
          <Icon fontSize="large">movie_filter</Icon>
        </MDBox>
      }
      cardTitle={`Media Gallery: ${company?.name}`}
      iconContainerSx={{ backgroundColor: "transparent" }}
      cardSx={{ width: "100%", overflow: "visible !important" }}
    >
      <MDBox>
        <ImageUploadDropzone
          title="Company Banner"
          listName="bannerUrls"
          attribute="bannerUrl"
          apiUrl={`/upload/company/${company?.slug}/banner`}
          componentType="company"
          typeOfUpload="banner"
          imageBaseUrl={company?.imageUrl}
          selectedImg={company?.bannerUrl}
          sourceComponent={sourceComponent}
          company={company}
          setCompany={setCompany}
          refetchCompany={refetchCompany}
        />

        <ImageUploadDropzone
          title="Company Square Logo"
          listName="squareLogoUrls"
          attribute="squareLogoUrl"
          apiUrl={`/upload/company/${company?.slug}/square-logo`}
          componentType="company"
          typeOfUpload="square-logo"
          imageBaseUrl={company?.imageUrl}
          selectedImg={company?.squareLogoUrl}
          sourceComponent={sourceComponent}
          company={company}
          setCompany={setCompany}
          refetchCompany={refetchCompany}
        />

        <ImageUploadDropzone
          title="Company Logo"
          listName="logoUrls"
          attribute="logoUrl"
          apiUrl={`/upload/company/${company?.slug}/logo`}
          componentType="company"
          typeOfUpload="logo"
          imageBaseUrl={company?.imageUrl}
          selectedImg={company?.logoUrl}
          sourceComponent={sourceComponent}
          company={company}
          setCompany={setCompany}
          refetchCompany={refetchCompany}
        />

        <ImageUploadDropzone
          title="Company Background"
          listName="backgroundImageUrls"
          attribute="backgroundImageUrl"
          apiUrl={`/upload/company/${company?.slug}/backgroundImage`}
          componentType="company"
          typeOfUpload="backgroundImage"
          imageBaseUrl={company?.imageUrl}
          selectedImg={company?.backgroundImageUrl}
          sourceComponent={sourceComponent}
          company={company}
          setCompany={setCompany}
          refetchCompany={refetchCompany}
        />
        <MDTypography
          variant="h6"
          color="dark"
          mb={2}
          style={{ padding: "0.5rem 0", borderBottom: "2px solid #7b809a66" }}
        >
          Company Attachments
        </MDTypography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <UploadFileModal
              label="Company Attachments"
              attachmentTypes={companyAttachmentTypes}
              typeOfUploader="company"
            />
          </Grid>
          <Grid item xs={9}>
            <Attachments isShownLabel={false} company={company} setCompany={setCompany} />
          </Grid>
        </Grid>
      </MDBox>
    </CustomCard>
  );
};

export default CompanyMedia;
