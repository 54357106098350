import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAppContextController } from "context/AppContext";
import { getUpdatedNotesList, sortArrayOfObj } from "utils/helpers/dataList";
import { isEmpty } from "lodash";
import { Box, Stack } from "@mui/material";
import CustomCard from "components/CustomCard";
import { TrendingUp } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import FileDropzone from "components/Dropzone";
import DataListContainer from "components/DataListContainer";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";
import PanelActions from "components/PanelActions";
import ConfirmPanelChanges from "components/ConfirmPanelChanges";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";

import SalesForm from "./SalesForm";
import SalesNotes from "./SalesNotes";

const emptyValues = {
  salesCycleStatus: "",
  lastContactDate: "",
  nextContactDate: "",
};

const SalesPanel = ({ currentData, onUpdate, page = "companies" }) => {
  const { isMobile, setCurrentFormDirty, changePanel } = useAppContextController();

  const [currentNote, setCurrentNote] = useState({});
  const [noteIndex, setNoteIndex] = useState(null);
  const [salesData, setSalesData] = useState({});
  const [isNotesModalOpen, setNotesModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const [changeToActive, setChangeToActive] = useState(false);
  const [isOpen, setOpen] = useState(false);


  let pageType
  switch (page) {
    case "companies":
      pageType = "Customer"
      break;
    case "venues":
      pageType = "Venue"
      break;
    case "partners":
      pageType = "Partner"
      break;

    default:
      pageType = "Unknown"
      break;
  }


  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    formState: { isDirty },
  } = useForm();

  const salesNotes = watch("salesNotes");

  const handleSave = (data) => {
    const updatedSales = {
      ...(changeToActive && { status: "Active" }),
      sales: { ...salesData, ...data },
    };
    onUpdate(updatedSales);
  };

  const handleNote = (idx, row) => {
    setNoteIndex(idx);
    if (idx === null) setCurrentNote({});
    else setCurrentNote(row);
    setNotesModalOpen(true);
  };

  const handleSaveNote = (data) => {
    const { text } = data;
    const updatedNotes = getUpdatedNotesList(text, noteIndex, salesNotes);
    if (!isEmpty(updatedNotes))
      setValue("salesNotes", sortArrayOfObj({ arr: updatedNotes }), { shouldDirty: true });
  };

  const handleRemoveNote = (idx) => {
    const temporaryNotes = salesNotes;
    const updatedNotes = temporaryNotes?.filter((_, index) => index !== idx);
    setValue("salesNotes", updatedNotes, { shouldDirty: true });
  };

  const handleCancelChanges = () => reset(salesData);

  const handleConfirmChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(handleSave)();
      changePanel();
      setCurrentFormDirty(false);
    }
  };

  useEffect(() => {
    if (currentData?.sales) {
      reset(currentData?.sales);
      setSalesData(currentData?.sales);
    } else {
      reset(emptyValues);
      setSalesData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData]);

  useEffect(() => {
    if (salesData?.salesNotes)
      setValue("salesNotes", sortArrayOfObj({ arr: salesData?.salesNotes }));
    else setValue("salesNotes", []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesData]);

  useEffect(() => {
    setCurrentFormDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <CustomCard
        icon={<TrendingUp color="white" />}
        cardTitle={`Sales: ${currentData?.name}`}
        cardActions={isDirty && <PanelActions onCancel={handleCancelChanges} />}
        {...customPanelCard(isMobile, "success")}
      >
        <Stack spacing={4} sx={styles.container}>
          <SalesForm
            control={control}
            setValue={setValue}
            selectedStatus={watch("salesCycleStatus")}
            setOpen={setOpen}
          />

          <Box>
            <DataListContainer
              onAdd={handleNote}
              data={salesNotes}
              renderRow={(row, idx) => (
                <SalesNotes row={row} idx={idx} onEdit={handleNote} onRemove={handleRemoveNote} />
              )}
              title="Notes"
              tableHeight={265}
              height={267}
              selected={noteIndex}
            />
          </Box>
        </Stack>

        <AddANoteModal
          open={isNotesModalOpen}
          toastAlert={toastAlert}
          setToastAlert={setToastAlert}
          setOpen={setNotesModalOpen}
          saveNote={handleSaveNote}
          showType={false}
          allowEmpty={false}
          defaultValue={currentNote?.text}
          onCloseAction={() => setNoteIndex(null)}
        />
      </CustomCard>
      <ConfirmPanelChanges onConfirm={handleConfirmChanges} />
      {isOpen && (
        <ConfirmModal
          title="Set Status to Active"
          description={`Do you want to change ${pageType} status to "Active"? This will take effect after changes are saved.`}
          negativeBtn={{
            label: "No",
            fn: () => {
              setChangeToActive(false);
              setOpen(false);
            },
            isShown: true,
            isOpen: true,
          }}
          positiveBtn={{
            label: "Yes",
            fn: () => {
              setChangeToActive(true);
              setOpen(false);
            },
            isShown: true,
          }}
          isOpen={isOpen}
          closeFn={() => setOpen(false)}
        />
      )}
    </form>
  );
};

export default SalesPanel;
