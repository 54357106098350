import { useState } from "react";

import { Button, FormHelperText, Grid } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import FormField from "layouts/applications/wizard/components/FormField";
import { PatternFormat } from "react-number-format";

import { transformYupErrorsIntoObject } from "utils/helpers/formHelpers";

import GenericModal from "components/GenericModal";
import additionalContactsSchema from "./AdditionalContactsSchema";

const defaultValues = {
  firstName: "",
  lastName: "",
  title: "",
  departmentLocation: "",
  email: "",
  phone: "",
};

const AdditionalContactsModal = ({
  contacts,
  currentContact,
  setCurrentContact,
  contactIndex,
  setContactIndex,
  onContactsChange,
  isModalOpen,
  setModalOpen,
  sourceComponent,
}) => {
  const [errors, setErrors] = useState();

  const reset = () => {
    setCurrentContact(defaultValues);
    setContactIndex(null);
    setErrors();
  };

  const handleClose = () => {
    setModalOpen(false);
    reset();
  };

  const handleChange = (name, value) => setCurrentContact({ ...currentContact, [name]: value });

  const handleSubmit = async () => {
    let updatedContacts;

    await additionalContactsSchema
      .validate(currentContact, { abortEarly: false })
      .then(() => {
        if (contactIndex !== null) {
          updatedContacts = contacts.map((item, index) => {
            if (index === contactIndex) {
              return currentContact;
            }
            return item;
          });
        } else {
          updatedContacts = [...contacts, currentContact];
        }

        onContactsChange(updatedContacts);
        handleClose();
      })
      .catch((error) => {
        const validationErrors = transformYupErrorsIntoObject(error);
        setErrors(validationErrors);
      });
  };

  const modalBody = (
    <>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={6}>
          <FormField
            value={currentContact?.firstName}
            onChange={(e) => handleChange("firstName", e.target.value)}
            label="First Name"
          />
          <FormHelperText error>{errors?.firstName}</FormHelperText>
        </Grid>
        <Grid item xs={6}>
          <FormField
            value={currentContact?.lastName}
            onChange={(e) => handleChange("lastName", e.target.value)}
            label="Last Name"
          />
          <FormHelperText error>{errors?.lastName}</FormHelperText>
        </Grid>
      </Grid>

      <Grid container spacing={3} mb={3}>
        <Grid item xs={6}>
          <FormField
            value={currentContact?.departmentLocation}
            onChange={(e) => handleChange("departmentLocation", e.target.value)}
            label="Department"
          />
          <FormHelperText error>{errors?.departmentLocation}</FormHelperText>
        </Grid>
        <Grid item xs={6}>
          <FormField
            value={currentContact?.title}
            onChange={(e) => handleChange("title", e.target.value)}
            label="Title"
          />
          <FormHelperText error>{errors?.title}</FormHelperText>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormField
            value={currentContact?.email}
            onChange={(e) => handleChange("email", e.target.value)}
            label="Email"
          />
          <FormHelperText error>{errors?.email}</FormHelperText>
        </Grid>
        <Grid item xs={6}>
          <PatternFormat
            format="(###) ### ####"
            value={currentContact?.phone}
            allowEmptyFormatting
            mask="_"
            label="Phone"
            onValueChange={({ value }) => handleChange("phone", value)}
            customInput={FormField}
          />
          <FormHelperText error>{errors?.phone}</FormHelperText>
        </Grid>
      </Grid>
    </>
  );

  const modalButtons = (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClose}
        sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        startIcon={<SaveIcon />}
        style={{ color: "white" }}
        sx={{ padding: "0.5rem 1rem", borderRadius: "4px" }}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </>
  );

  return (
    <>
      <GenericModal
        open={isModalOpen}
        setOpen={setModalOpen}
        header={`Additional ${sourceComponent} Contacts`}
        body={modalBody}
        buttons={modalButtons}
        width="30%"
        borderRadius="4px"
      />
    </>
  );
};

export default AdditionalContactsModal;
