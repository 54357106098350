import {
  Icon,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography as MDTypography,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { getCommonBaseImageUrl } from "utils/helpers/upload";
import { ImageSearch } from "@mui/icons-material";
import RecognitionDetailsModal from "components/RecognitionDetailsModal";

const IMAGE_EXTENSIONS = ["jpeg", "jpg", "png", "gif", "heic", "jfif", "svg", "webp"];

const FileViewer = ({
  file,
  currentApplicant,
  IMAGE_SERVER,
  company,
  size = 100,
  onDelete,
  isUser,
  currentLoggedUser,
  editFileNameHandler,
  confirmFileNameChanges,
  cancelFileNameChanges,
}) => {
  const [usePresigned, setUsePresigned] = useState(true);
  const [recognitionDetailModalOpen, setRecognitionDetailModalOpen] = useState(false);
  const getDirectUrl = () => {
    return `${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${file.type}/${file.name}`;
  };

  const handleFileOpen = () => {
    if (usePresigned && file.presignedUrl) {
      fetch(file.presignedUrl, { method: "HEAD" })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Presigned URL failed");
          }
          window.open(file.presignedUrl);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("Presigned URL failed, falling back to direct URL");
          setUsePresigned(false);
          window.open(getDirectUrl());
        });
    } else {
      window.open(getDirectUrl());
    }
  };

  const getFilePreview = (height) => {
    if (IMAGE_SERVER && IMAGE_EXTENSIONS.includes(file.docType.toLowerCase())) {
      return (
        <img
          src={getDirectUrl()}
          alt={`${file.type} preview`}
          height={height}
          onError={() => setUsePresigned(false)}
        />
      );
    }
    if (company?.imageUrl && company?.uploadUrl) {
      const baseUrl = getCommonBaseImageUrl(company);
      if (file.docType.toLowerCase() === "pdf")
        return <img src={`${baseUrl}/static/pdf-icon.png`} alt="PDF" width={size} height={size} />;
      if (file.docType.toLowerCase() === "docx")
        return (
          <img src={`${baseUrl}/static/word-icon.png`} alt="Word Doc" width={size} height={size} />
        );
    }
    return null;
  };

  useEffect(() => {
    if (!file.presignedUrl) setUsePresigned(false);
  }, [file.presignedUrl]);

  return (
    <>
      <Stack container="true" justifyContent="space-evenly" alignItems="center" mb={3}>
        {/* Delete button */}
        {!isUser && currentLoggedUser && (
          <MDBox
            sx={{
              position: "absolute",
              right: "-10px",
              top: "-10px",
              backgroundColor: "white",
              padding: 1,
              borderRadius: "25px",
              display: "flex",
              flexDirection: "column",
              zIndex: 1,
            }}
          >
            <IconButton
              color="info"
              // className={styles.maps_section_image_delete}
              onClick={onDelete}
            >
              <Icon fontSize="small">cancel</Icon>
            </IconButton>
            {file.recognition && (
              <IconButton color="info" onClick={() => setRecognitionDetailModalOpen(true)}>
                <ImageSearch />
              </IconButton>
            )}
          </MDBox>
        )}

        {/* File preview */}
        <MDBox
          onClick={handleFileOpen}
          sx={{
            cursor: "pointer",
            "&:hover": { opacity: 0.8 },
            position: "relative",
          }}
        >
          {getFilePreview(size)}
        </MDBox>

        {/* File details and editor */}
        <Stack spacing={1} alignItems="center">
          <MDTypography variant="h6">{file.type}</MDTypography>
          <TextField
            disabled={isUser}
            defaultValue={file.name?.substring(0, file.name.lastIndexOf("."))}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            value={file.editedName?.substring(0, file.editedName.lastIndexOf("."))}
            onInput={(e) => editFileNameHandler(e, file)}
          />
          {file.isEdited && (
            <Stack direction="row" spacing={1} mt={1}>
              <Tooltip title="Confirm" placement="bottom">
                <CheckIcon
                  color="success"
                  onClick={(e) => confirmFileNameChanges(e, file)}
                  fontSize="large"
                  sx={{
                    "&": { transition: "0.1s" },
                    "&:hover": { background: "#D3D3D3" },
                  }}
                />
              </Tooltip>
              <Tooltip title="Cancel" placement="bottom">
                <CloseIcon
                  color="error"
                  onClick={(e) => cancelFileNameChanges(e, file)}
                  fontSize="large"
                  sx={{
                    "&": { transition: "0.1s" },
                    "&:hover": { background: "#D3D3D3" },
                  }}
                />
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Stack>
      {file.recognition && recognitionDetailModalOpen && (
        <RecognitionDetailsModal
          recognition={file.recognition}
          getFilePreview={getFilePreview}
          open={recognitionDetailModalOpen}
          setOpen={setRecognitionDetailModalOpen}
        />
      )}
    </>
  );
};

export default FileViewer;
