import { useNavigate } from "react-router-dom";
import { Grid, Tooltip, Badge } from "@mui/material";
import MDBox from "components/MDBox";
import NumbersIcon from "@mui/icons-material/Numbers";
import CheckIcon from "@mui/icons-material/Check";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import StadiumIcon from "@mui/icons-material/Stadium";

const EventNumbers = ({
  eventUrl,
  requested,
  onRoster,
  onWaitlist,
  onRequest,
  onPremise,
  fontSize = "large",
  setNavigateUrl,
}) => {
  const navigate = useNavigate();
  const navigateLocal = setNavigateUrl ?? navigate;

  const navigateToRoster = (e) => {
    e.stopPropagation();
    navigateLocal(`/events/${eventUrl}/action/eventsroster`);
  };
  return (
    <MDBox display="flex" justifyContent={{ xs: "center", tablet: "end" }} flexWrap="wrap" mr={3}>
      <Grid container direction="row" sx={{ gap: "15px" }} justifyContent="end">
        <Grid item pl={0} pr={1}>
          <Tooltip title={`${requested} Needed`}>
            <Badge
              badgeContent={requested?.toString()}
              max={Infinity}
              color="info"
              onClick={(e) => navigateToRoster(e)}
            >
              <NumbersIcon fontSize={fontSize} color="info" />
            </Badge>
          </Tooltip>
        </Grid>
        <Grid item pl={1} pr={1}>
          <Tooltip title={`${onRoster} On Roster`}>
            <Badge
              badgeContent={onRoster?.toString() || 0}
              color="primary"
              onClick={(e) => navigateToRoster(e)}
            >
              <CheckIcon fontSize={fontSize} color="success" />
            </Badge>
          </Tooltip>
        </Grid>{" "}
        <Grid item pl={1} pr={1}>
          <Tooltip title={`${onWaitlist} Waitlisted`}>
            <Badge
              badgeContent={onWaitlist?.toString() || 0}
              color="secondary"
              onClick={(e) => navigateToRoster(e)}
            >
              <HourglassBottomIcon fontSize={fontSize} color="secondary" />
            </Badge>
          </Tooltip>
        </Grid>
        <Grid item pl={1} pr={0}>
          <Tooltip title={`${onRequest} Requested`}>
            <Badge
              badgeContent={onRequest?.toString() || 0}
              color="warning"
              onClick={(e) => navigateToRoster(e)}
            >
              <QuestionMarkIcon fontSize={fontSize} color="warning" />
            </Badge>
          </Tooltip>
        </Grid>
        {onPremise && onPremise !== "0" ? (
          <Grid item pl={1} pr={0}>
            <Tooltip title={`${onPremise} On Premise`}>
              <Badge
                badgeContent={onPremise || 0}
                color="success"
                onClick={(e) => navigateToRoster(e)}
              >
                <StadiumIcon fontSize={fontSize} color="info" />
              </Badge>
            </Tooltip>
          </Grid>
        ) : null}
      </Grid>
    </MDBox>
  );
};

export default EventNumbers;
