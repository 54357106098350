import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -16,
    top: -5,
    // border: `2px solid ${theme.palette.background.paper}`,
    paddingRight: "6px",
    paddingLeft: "6px",
    paddingTop: "3px",
    paddingBottom: "3px",
    fontSize: "0.7rem",
    opacity: 0.9,
    height: 'unset',
  },
}));

const FilterButtons = ({ filter, setFilter, options, name, isDisabled, fontSize, sx, itemsx }) => {
  return (
    <ToggleButtonGroup
      color="secondary"
      value={filter?.[name]}
      exclusive
      onChange={(_, value) => value && setFilter((prevState) => ({ ...prevState, [name]: value }))}
      aria-label="filter-button-group"
      sx={{ display: "flex", justifyContent: "center", width: "100%", background: "white", ...sx }}
    >
      {options.map((item) => (
        <ToggleButton
          value={item.value}
          sx={{ gap: "5px", width: "100%", background: "white", ...itemsx }}
          key={item.title}
          disabled={isDisabled}
        >
          {item.icon}
          <StyledBadge badgeContent={item.count ?? 0} color="info" size="lg" max={10000}>
            <Typography fontWeight="medium" sx={{ fontSize: fontSize ?? "0.75rem" }}>
              {item.title}
            </Typography>
          </StyledBadge>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default FilterButtons;
