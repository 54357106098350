import { Box, Divider, Grid, styled, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import createCompany from "api/companies/createCompany";
import updateCompany from "api/companies/updateCompany";
import colors from "assets/theme-dark/base/colors";
import DataListContainer from "components/DataListContainer";
import FormTextEditor from "components/FormTextEditor";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import MDTypography from "components/MDTypography";
import { IMG_SERVER } from "config";
import { useAppContextController } from "context/AppContext";
import { createCompanyInfoSchema, DEFAULT_CUSTOMER } from "data/companyInfo";
import useCompanyCache from "hooks/useCompanyCache";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import useCompanyStore from "stores/useCompanyStore";
import { getDirtyFields } from "utils/helpers/formHelpers";
import AddCompanyLocationModal from "../CompanyInfo/AddCompanyLocationModal";
import CompanyFormActions from "./CompanyFormActions";
import CompanyLocationsItem from "../CompanyLocationsItem";
import AdditionalContactsItem from "../AdditionalContactsItem";
import AdditionalContactsModal from "../AdditionalContactsModal";
import CompanyPreview from "../CompanyPreview";
import CompanyHeader from "./CompanyHeader";
import CompanyInformationSection from "../CompanyInformationSection";
import CompanyConfiguration from "../CompanyConfiguration";

const InputFlexWrapper = ({ children, ...props }) => (
  <FlexWrapper maxWidth={{ lg: "47%", sm: "100%" }} marginBottom={2.5} {...props}>
    {children}
  </FlexWrapper>
);

const CompanyForm = ({ onSubmit, setRefreshCompanies }) => {
  const { setCompanyFormState, companyFormState, setCurrentCompany, currentCompany } =
    useCompanyStore((state) => ({
      setCompanyFormState: state.setCompanyFormState,
      companyFormState: state.companyFormState,
      setCurrentCompany: state.setCurrentCompany,
      currentCompany: state.currentCompany,
    }));

  const { company, setCompany, setCompanyType, currentLoggedUser } = useAppContextController();

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [companyStatus, setCompanyStatus] = useState("Active");

  // const resolver = useYupValidationResolver(companyInfoSchema);
  const isEdit = !!currentCompany?._id;
  const validationSchema = createCompanyInfoSchema(companyStatus, isEdit);
  const resolver = useYupValidationResolver(validationSchema);

  const [addCompanyLocationModalOpen, setAddCompanyLocationModalOpen] = useState(false);
  // const [isBannerOpen, setIsBannerOpen] = useState(false);
  // const [isLogoOpen, setIsLogoOpen] = useState(false);
  // const [isSquareLogoOpen, setIsSquareLogoOpen] = useState(false);
  // const [isBackgroundOpen, toggleBackgroundOpen] = useState(false);
  // const [backgroundAnchorRef, setBackgroundAnchorRef] = useState();
  // const [bannerAnchorRef, setBannerAnchorRef] = useState();
  // const [logoAnchorRef, setLogoAnchorRef] = useState();
  // const [squareLogoAnchorRef, setSquareLogoAnchorRef] = useState();
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locationIndex, setLocationIndex] = useState(null);
  const [locationList, setLocationList] = useState([]);
  // const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const [isAdditionalContactsModalOpen, setAdditionalContactsModalOpen] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [contactIndex, setContactIndex] = useState(null);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    watch,
    reset,
    setError,
    trigger,
    formState,
  } = useForm({ resolver });
  const { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful, dirtyFields } = formState;

  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);
  const { mutateAsync: createCompanyMutation } = useMutation(createCompany);

  const watchStatus = watch("status", companyStatus); // Watching the 'status' field for changes

  useEffect(() => {
    setCompanyStatus(watchStatus); // Update the state whenever the 'status' changes
  }, [watchStatus]);

  const saveAdditionalDetails = async (values) => {
    if (!currentCompany?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updateCompanyMutation(
        { companyId: currentCompany._id, body: values },
        {
          onSuccess: async (res) => {
            setRefreshCompanies((prev) => !prev);
            setCurrentCompany({ ...currentCompany, ...values });
            if (currentCompany?.primaryCompany) {
              setCompany({ ...company, ...values });
            }
            enqueueSnackbar("Company has been updated.", { variant: "success" });
            refetchCompany();
            await queryClient.invalidateQueries("companies");
          },
        }
      );
    }
  };

  // const handleOpenAttachmentsMenu = (e) => setIsAttachmentOpen(true);
  // const handleCloseBannerMenu = (e) => setIsBannerOpen(false);
  // const handleCloseLogoMenu = (e) => setIsLogoOpen(false);
  // const handleCloseSquareLogoMenu = (e) => setIsSquareLogoOpen(false);
  // const handleCloseBgMenu = (e) => toggleBackgroundOpen(false);

  const openPreviewModal = (content) => {
    setPreviewContent(content);
    setPreviewOpen(!isPreviewOpen);
  };

  const updateCompanyHandler = (data) => {
    updateCompanyMutation(
      {
        companyId: currentCompany?._id,
        body: {
          ...getDirtyFields(data, dirtyFields),
        },
      },
      {
        onSuccess: () => {
          setRefreshCompanies((prev) => !prev);
          setCurrentCompany({ ...currentCompany, ...data });
          if (currentCompany?.primaryCompany) {
            setCompany({ ...company, ...data });
          }
          enqueueSnackbar("Company has been updated.", { variant: "success" });
          reset(data, { keepValues: true });
        },
      }
    );
  };

  const createCompanyHandler = async (values) => {
    const data = values;
    data.createAgent = currentLoggedUser._id;
    data.imageUrl = isEmpty(company)
      ? `${IMG_SERVER}/${values.slug}`
      : `${IMG_SERVER}/${company.slug}`;

    await createCompanyMutation(data, {
      onSuccess: (res) => {
        if (res.acknowledged) {
          setRefreshCompanies((prev) => !prev);
          onSubmit?.();
          enqueueSnackbar("Customer has been created!", { variant: "success" });
          setCurrentCompany({ _id: res.insertedId, ...data });
          navigate(`/customers/${data.slug}`);
        }
      },
      onError: (err) => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    });
  };

  const handleLocation = (idx, row, isOpen) => {
    setLocationIndex(idx);
    setCurrentLocation(row);
    setAddCompanyLocationModalOpen(isOpen);
  };

  const handleContact = (idx, row, isOpen) => {
    setContactIndex(idx);
    setCurrentContact(row);
    setAdditionalContactsModalOpen(isOpen);
  };

  const handleRemoveLocation = (idx) => {
    const temporaryLocations = getValues("locations");
    const updatedLocations = temporaryLocations.filter((_, index) => index !== idx);
    setValue("locations", updatedLocations);
    saveAdditionalDetails({ locations: updatedLocations });
    setAddCompanyLocationModalOpen(false);
  };

  const renderCompanyLocations = (row, idx) => (
    <CompanyLocationsItem
      row={row}
      idx={idx}
      onEdit={handleLocation}
      onRemove={handleRemoveLocation}
    />
  );

  const handleRemoveContact = (idx) => {
    const temporaryContacts = getValues("additionalContacts");
    const updatedContacts = temporaryContacts.filter((_, index) => index !== idx);
    setValue("contacts", updatedContacts);
    saveAdditionalDetails({ additionalContacts: updatedContacts });
    setAdditionalContactsModalOpen(false);
  };

  const renderAdditionalContacts = (row, idx) => (
    <AdditionalContactsItem
      row={row}
      idx={idx}
      onEdit={handleContact}
      onRemove={handleRemoveContact}
    />
  );

  const handleContactsChange = (contacts) => {
    setValue("additionalContacts", contacts, { shouldDirty: true });
    saveAdditionalDetails(getValues());
  };

  useEffect(() => {
    if (!isEmpty(currentCompany)) {
      delete currentCompany?.longitude;
      delete currentCompany?.latitude;
      delete currentCompany?.radius;
    }

    reset(isEdit ? currentCompany : DEFAULT_CUSTOMER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, isEdit]);

  useEffect(() => {
    setCompanyFormState({ ...companyFormState, isDirty, dirtyFields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, dirtyFields]);

  useEffect(() => {
    if (!company || !company.locations || !getValues("locations")) setLocationList([]);
    else {
      setLocationList(
        getValues("locations")?.map((location, index) => ({ label: location?.locationName, index }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, getValues("locations")]);

  return (
    <>
      <FlexWrapper justifyContent="space-between">
        <CompanyHeader sourceComponent="Company" />
        <CompanyFormActions
          sourceComponent="Company"
          isDirty={isDirty}
          dirtyFields={dirtyFields}
          resetChanges={() => {
            reset(currentCompany);
          }}
          currentCompany={currentCompany}
          onPreview={() => openPreviewModal("all")}
        />
      </FlexWrapper>
      <form
        onSubmit={handleSubmit(isEdit ? updateCompanyHandler : createCompanyHandler)}
        id="company-form"
        style={{ width: "100%" }}
      >
        <Grid container spacing={3} padding={3} paddingRight={0}>
          <Grid container xs={12} sm={12} md={12} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FlexWrapper
                flexWrap="wrap"
                alignContent="flex-start"
                justifyContent="flex-start"
                maxWidth={{ lg: "96%", sm: "100%", xs: "100%" }}
                height="100%"
                flexDirection="column"
              >
                <InputFlexWrapper
                  justifyContent="flex-start"
                  alignSelf="flex-start"
                  maxWidth="100%"
                  minHeight={38}
                >
                  <MDTypography variant="p" fontSize="18px" color="info" fontWeight="bold">
                    Primary Company Information
                  </MDTypography>
                </InputFlexWrapper>
                <CompanyInformationSection
                  control={control}
                  setValue={setValue}
                  isEdit={isEdit}
                  selectedStatus={watch("status")}
                  isCompany
                />
                <InputFlexWrapper maxWidth="100%" width="100%" mt={2}>
                  <DataListContainer
                    tableHeight={220}
                    height={225}
                    onAdd={handleLocation}
                    data={watch("locations")}
                    renderRow={renderCompanyLocations}
                    title="Company Locations"
                  />
                </InputFlexWrapper>
                <InputFlexWrapper maxWidth="100%" width="100%">
                  <DataListContainer
                    tableHeight={220}
                    height={225}
                    onAdd={handleContact}
                    data={watch("additionalContacts")}
                    renderRow={renderAdditionalContacts}
                    title="Additional Company Contacts"
                  />
                </InputFlexWrapper>

                <FormTextEditor
                  title="Company Contact Message"
                  name="companyContactMessage"
                  onClick={() => openPreviewModal("companyContactMessage")}
                  control={control}
                  height="10rem"
                />
              </FlexWrapper>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={12} md={12} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FlexWrapper
                flexWrap="wrap"
                alignContent="flex-start"
                justifyContent="flex-start"
                maxWidth={{ lg: "96%", sm: "100%", xs: "100%" }}
                height="100%"
                flexDirection="column"
              >
                <CompanyConfiguration
                  control={control}
                  sourceComponent="Company"
                  setValue={setValue}
                  onBlur={() => trigger("accountManager")}
                />
                <InputFlexWrapper maxWidth="100%" width="100%" mt={2}>
                  <FormTextEditor
                    title="Company Description"
                    name="description"
                    onClick={() => openPreviewModal("description")}
                    control={control}
                    height="14.025rem"
                  />
                  <FormTextEditor
                    title="Acknowledgment"
                    name="acknowledgmentText"
                    onClick={() => openPreviewModal("acknowledgmentText")}
                    control={control}
                    height="14.025rem"
                  />

                  <FormTextEditor
                    title="Onboarding Instructions"
                    name="onboardingText"
                    onClick={() => openPreviewModal("onboardingText")}
                    control={control}
                  />
                  <FormTextEditor
                    title="Decline Job"
                    name="declineJobPageText"
                    onClick={() => openPreviewModal("declineJobPageText")}
                    control={control}
                  />
                </InputFlexWrapper>
              </FlexWrapper>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {addCompanyLocationModalOpen && (
        <AddCompanyLocationModal
          setValue={setValue}
          getValues={getValues}
          saveChanges={saveAdditionalDetails}
          addCompanyLocationModalOpen={addCompanyLocationModalOpen}
          setAddCompanyLocationModalOpen={setAddCompanyLocationModalOpen}
          locations={currentCompany?.locations}
          currentCompany={currentCompany}
          currentLocation={currentLocation}
          setCurrentLocation={setCurrentLocation}
          locationIndex={locationIndex}
          setLocationIndex={setLocationIndex}
          isEdit={isEdit}
        />
      )}
      {isAdditionalContactsModalOpen && (
        <AdditionalContactsModal
          contacts={watch("additionalContacts")}
          currentContact={currentContact}
          setCurrentContact={setCurrentContact}
          contactIndex={contactIndex}
          setContactIndex={setContactIndex}
          onContactsChange={handleContactsChange}
          isModalOpen={isAdditionalContactsModalOpen}
          setModalOpen={setAdditionalContactsModalOpen}
          sourceComponent="Company"
        />
      )}
      {isPreviewOpen && (
        <CompanyPreview
          open={isPreviewOpen}
          onClose={() => setPreviewOpen(!isPreviewOpen)}
          content={previewContent}
        />
      )}
      {/* {isAttachmentOpen && (
        <UploadFileModal
          isOpen={isAttachmentOpen}
          setIsOpen={setIsAttachmentOpen}
          label="Upload Company File"
          attachmentTypes={companyAttachmentTypes}
          typeOfUploader="company"
        />
      )} */}
    </>
  );
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  opacity: 1,
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  width: "100%",
  margin: "2rem 0 0.5rem 0",
}));

const StyledImageUploadContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    height: "100%",
    width: "100%",
    "& img": {
      width: "100%",
      height: "auto",
      objectFit: "fill",
      aspectRatio: "16/9",
    },
  },
  "& img": {
    borderRadius: "12px",
  },
  cursor: "pointer",
  position: "relative",
}));

const StyledImageSquareLogo = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  maxWidth: "60px",
  maxHeight: "60px",
  background: colors.background.default,
  objectFit: "contain",
}));

const StyledImageLogo = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  background: colors.background.default,
  objectFit: "contain",
}));

const StyledImageBanner = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const StyledTypographyError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "0.75rem",
}));

export default CompanyForm;
