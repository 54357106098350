import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { Box, Stack } from "@mui/material";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { useAppContextController } from "context/AppContext";
import updatePartner from "layouts/pages/partners/actions/updatePartner";
import CustomCard from "components/CustomCard";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";
import DataListContainer from "components/DataListContainer";
import { memberSchema } from "data/member";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import TaskDetailsModal from "layouts/pages/tasks/components/TaskDetailsModal";
import MembersModal from "../MembersModal";
import PartnerMemberItem from "../PartnerContacts/PartnerMemberItem";
import MembersPanelActions from "../MembersPanelActions";
import MembersExportFieldsModal from "./MembersExportFieldsModal";
import ApplicantNotesList from "../../../../../components/ApplicantNotesList";

const emptyValues = {
  firstName: "",
  lastName: "",
  status: "Active",
  phone: "",
  email: "",
  tShirtSize: null,
};

const memberFields = ["member", "firstName", "lastName", "email", "phone", "title"];

const PartnerMembers = ({ setSelectedPanel, setSelectedMember }) => {
  const {
    currentPartner,
    setCurrentPartner,
    currentLoggedUser,
    isMobile,
    setCurrentFormDirty,
    isCurrentFormDirty,
    setPanelChangesOpen,
    setChangePanel,
    changePanel,
    currentApplicant,
    setCurrentApplicant,
  } = useAppContextController();

  // MEMBERS MODAL
  const [membersModalOpen, setMembersModalOpen] = useState(false);
  const [dropdowns, setDropdowns] = useState();
  const resolver = useYupValidationResolver(memberSchema(dropdowns));
  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    getValues,
    reset,
    setValue,
    setError,
    control,
    watch,
    Control,
    formState: { errors, isSubmitting, isDirty, dirtyFields },
  } = useForm({ mode: "onBlur", defaultValues: emptyValues, resolver });
  // -------------

  const [toastAlert, setToastAlert] = useState(false);
  const [memberIndex, setMemberIndex] = useState(null);
  const [currentMember, setCurrentMember] = useState(null);
  const [isNotesModalOpen, setNotesModalOpen] = useState(false);
  const [exportFieldModalOpen, setExportFieldModalOpen] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);

  const handleAddMember = (e) => {
    setCurrentMember(null);
    setMembersModalOpen(true);
  };

  const memberNotes = watch("notes");

  const allOptions = {
    fetchAll: true,
    filters: {
      status: "Member",
      "partners.partnerSlug": currentPartner.slug,
    },
    orderByOverride: "firstName:asc,lastName:asc",
  };

  const {
    data: fetchedMembers,
    isLoading,
    refetch: refetchMembers,
  } = useQuery(["members", allOptions], () => fetchApplicants(allOptions), {
    select: (data) => {
      if (!data?.data) return [];
      const newData = data.data.map((mem) => {
        const partnerItem = (mem.partners ?? []).find((p) => p.partnerSlug === currentPartner.slug);
        let partnerStatus = "";
        if (partnerItem) {
          partnerStatus = partnerItem.status;
        }
        return {
          ...mem,
          partnerStatus,
        };
      });
      return newData;
    },
  });

  const members = fetchedMembers ?? [];

  useEffect(() => {
    if (members?.length && currentMember) {
      const newCurrentMember = members.find((mem) => mem._id === currentMember._id);
      if (newCurrentMember) setCurrentMember(newCurrentMember);
    }
  }, [members]);

  const { mutateAsync: updatePartnerMutation } = useMutation(updatePartner);
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  // DELETE MEMBER
  const [idToDelete, setIdToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: (err) => {
      console.log("error", err);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    },
    onSuccess: async (_, { data }) => {
      queryClient.invalidateQueries(["partnersapplicants"]);
      queryClient.invalidateQueries(["members"]);
      if (data.status === "Deleted") {
        enqueueSnackbar("Member was archived", { variant: "success" });
      } else {
        enqueueSnackbar("Member has been updated!", { variant: "success" });
      }
    },
  });

  const handleDeleteMember = (id) => {
    setIdToDelete(id);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  // DELETE RECORD
  // const handleConfirmDeleteMember = async () => {
  //   if (!idToDelete) return;
  //   await updateApplicantMutation.mutateAsync({
  //     applicantId: idToDelete,
  //     data: { status: "Deleted" },
  //   });
  //   handleCloseDeleteModal();
  // };

  const handleConfirmDeleteMember = async () => {
    if (!idToDelete || !members?.length || !currentPartner) {
      handleCloseDeleteModal();
      return;
    }
    const foundMember = members.find((mem) => mem._id === idToDelete);
    if (foundMember) {
      const newPartners = (foundMember.partners ?? []).filter(
        (par) => par.partnerSlug !== currentPartner.slug
      );
      await updateApplicantMutation.mutateAsync({
        applicantId: idToDelete,
        data: { partners: newPartners },
      });
    }
    handleCloseDeleteModal();
  };
  // ----------------

  const handleOpenNotesModal = (idx) => {
    if (idx == null) return;
    const member = members[idx];
    if (member) {
      setCurrentMember(member);
      setMemberIndex(idx);
      setNotesModalOpen(true);
    }
  };

  const handleNotesSuccess = () => {
    queryClient.invalidateQueries(["partnersapplicants"]);
    queryClient.invalidateQueries(["members"]);
  };

  const openMemberInfo = (idx) => {
    if (idx == null) return;
    const member = members[idx];
    if (member) {
      setCurrentMember(member);
      setMemberIndex(idx);
      setMembersModalOpen(true);
    }
  };

  const renderAdditionalMembers = (row, idx) => (
    <PartnerMemberItem
      row={row}
      idx={idx}
      setSelectedMember={setSelectedMember}
      onEdit={openMemberInfo}
      onRemove={handleDeleteMember}
      onAddNote={handleOpenNotesModal}
    />
  );

  return (
    <form onSubmit={() => {}}>
      <CustomCard
        icon={<Diversity3Icon color="white" />}
        cardTitle={`Members: ${currentPartner.slug}`}
        cardActions={<MembersPanelActions onPressExport={() => setExportFieldModalOpen(true)} />}
        {...customPanelCard(isMobile, "warning")}
      >
        <Stack spacing={4} sx={styles.container}>
          <Box>
            <DataListContainer
              onAdd={handleAddMember}
              data={members}
              renderRow={renderAdditionalMembers}
              title="Members"
              tableHeight="680px"
              height="680px"
              // selected={memberIndex}
              isLoading={isLoading}
            />
          </Box>
        </Stack>
        {isDeleteModalOpen && (
          <ConfirmModal
            title="Remove Member"
            description="Please confirm you want to REMOVE this member."
            isOpen={isDeleteModalOpen}
            negativeBtn={{
              label: "Cancel",
              fn: handleCloseDeleteModal,
              isShown: true,
            }}
            positiveBtn={{
              label: "Confirm",
              fn: handleConfirmDeleteMember,
              isShown: true,
            }}
          />
        )}
        {membersModalOpen && (
          <MembersModal
            currentApplicant={currentMember}
            open={membersModalOpen}
            setDropdowns={setDropdowns}
            setOpen={setMembersModalOpen}
            handleSubmit={handleSubmit}
            Controller={Controller}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            reset={reset}
            errors={errors}
            currentPartner={currentPartner}
            dirtyFields={dirtyFields}
            getValues={getValues}
            setError={setError}
          />
        )}
        {exportFieldModalOpen && (
          <MembersExportFieldsModal
            exportFieldModalOpen={exportFieldModalOpen}
            setExportFieldModalOpen={setExportFieldModalOpen}
            applicantsData={members}
            currentPartner={currentPartner}
          />
        )}
        {isNotesModalOpen && (
          <ApplicantNotesList
            currentApplicant={currentMember}
            open={isNotesModalOpen}
            setOpen={setNotesModalOpen}
            onSuccess={handleNotesSuccess}
            isCreateTaskAllowed
            setTaskModalOpen={setTaskModalOpen}
          />
        )}
        <TaskDetailsModal
          mode="create"
          setTaskModalOpen={setTaskModalOpen}
          taskModalOpen={taskModalOpen}
          currentTask={null}
          setToastAlert={setToastAlert}
          currentApplicant={currentMember}
          parent="Notes"
        />
      </CustomCard>
    </form>
  );
};

export default PartnerMembers;
