import { useEffect } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { Checkbox, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Controller, useForm } from "react-hook-form";
import useDropdowns from "hooks/useDropdowns";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDTypography from "components/MDTypography";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { directDepositSchema } from "data/directDeposit";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { useAppContextController } from "context/AppContext";
import DirectDepositCommonFields from "./DirectDepositCommonFields";

const defaultValues = {
  account1: "",
  account2: "",
  accountType1: "",
  accountType2: "",
  amountPercentage1: "",
  amountPercentage2: "",
  bankName1: "",
  bankName2: "",
  date: new Date().toISOString(),
  paymentType: "",
  routing1: "",
  routing2: "",
};

const DirectDepositFormFields = ({ onSubmit }) => {
  const resolver = useYupValidationResolver(directDepositSchema);
  const { applicant, updateButtons, buttonState, currentFormState, updateCurrentFormState } =
    useNewApplicantContext();

  const { user } = useAppContextController();

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    trigger,
    getValues,
    formState: { errors, isDirty, isValid, isSubmitSuccessful, submitCount },
  } = useForm({ defaultValues, resolver, mode: "onBlur" });

  const isDirectDeposit = ["EmployerIssuedPaperCheck", "MoneyNetworkService"].includes(
    watch("paymentType")
  );

  const handleCheckStubOptionsChange = (e) => {
    setValue("checkStubOptions", e.target.id, { shouldDirty: true });
  };

  const handlePaymentTypeChange = (e) => {
    setValue("paymentType", e.target.id, { shouldDirty: true });
  };

  const { dropdowns: accountTypes } = useDropdowns({
    entity: "accountTypes",
    outsideMode: user ? "" : "protected",
  });

  useEffect(() => {
    reset(
      {
        ...defaultValues,
        ...(applicant.directDeposit ?? {}),
      },
      { keepErrors: false }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicant?._id]);

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    if (isValid) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isValid]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
        submit: {
          disabled: false,
          show: true,
        },
      });
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (!isValid && submitCount < 1) {
      const bankInfoArr = [
        "account1",
        "bankName1",
        "accountType1",
        "routing1",
        "amountPercentage1",
        "account2",
        "bankName2",
        "accountType2",
        "routing2",
        "amountPercentage2",
      ];
      const subscription = watch((formData, { name, type }) => {
        if (bankInfoArr.includes(name)) {
          if (formData[name]) {
            setValue("paymentType", "DirectDeposit", { shouldDirty: true });
            if (name !== "amountPercentage1" || name !== "amountPercentage2") {
              if (!getValues(`amountPercentage${name.at(-1)}`)) {
                setValue(`amountPercentage${name.at(-1)}`, 100, { shouldDirty: true });
              }
            }
          }
        }
      });
      return () => subscription.unsubscribe();
    }
    return "";
  }, [watch, submitCount, isValid]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} id="current-form">
      <Grid container spacing={3}>
        <DirectDepositCommonFields
          control={control}
          isDirectDeposit={isDirectDeposit}
          accountTypes={accountTypes}
          setValue={setValue}
          title="Bank One Information"
          number="1"
          trigger={trigger}
        />

        <DirectDepositCommonFields
          control={control}
          isDirectDeposit={isDirectDeposit}
          accountTypes={accountTypes}
          setValue={setValue}
          title="Bank Two Information"
          number="2"
        />
        {/* <Grid item sm={12}>
          <MDTypography variant="h5"> Check Stub Options:</MDTypography>
        </Grid>
        <Grid item sm={12}>
          <Controller
            name="checkStubOptions"
            control={control}
            render={({ field }) => (
              <>
                <Grid container>
                  <Grid item sm={6}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="PrintStubOnly"
                        checked={field.value === "PrintStubOnly"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handleCheckStubOptionsChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">
                        {" "}
                        Print Stub Only
                      </MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={6}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="EmailStubOnly"
                        checked={field.value === "EmailStubOnly"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handleCheckStubOptionsChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">
                        Email Stub Only
                      </MDTypography>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          />
        </Grid> */}
        <Grid item xs={12} sm={4}>
          <CustomTextField
            variant="outlined"
            disabled
            label="Email Address"
            value={applicant?.email || ""}
          />
        </Grid>
        <Grid item sm={8} />
        <Grid item xs={12} sm={4}>
          <CustomTextField
            variant="outlined"
            disabled
            label="Employee Name"
            value={`${applicant?.firstName} ${applicant?.lastName}`}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomTextField
            variant="outlined"
            disabled
            label="Social Security"
            value={applicant?.socialSecurity || ""}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Date"
                  renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                  {...field}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item sm={12}>
          <MDTypography variant="h5"> Payment Type Options:</MDTypography>
        </Grid>
        <Grid item sm={12}>
          <Controller
            name="paymentType"
            control={control}
            render={({ field }) => (
              <>
                <Grid container>
                  <Grid item xs={12} lg={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="DirectDeposit"
                        checked={field.value === "DirectDeposit"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handlePaymentTypeChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">
                        Direct deposit
                      </MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="MoneyNetworkService"
                        checked={field.value === "MoneyNetworkService"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handlePaymentTypeChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">
                        Money Network Service
                      </MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="EmployerIssuedPaperCheck"
                        checked={field.value === "EmployerIssuedPaperCheck"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handlePaymentTypeChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">
                        Employer-Issued Paper Check
                      </MDTypography>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          />
        </Grid>
      </Grid>
      <FormErrors errors={errors} />
    </form>
  );
};

export default DirectDepositFormFields;
