/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { useAppContextController } from "context/AppContext";
import useDropdowns from "hooks/useDropdowns";
import parse from "html-react-parser";
import { isEmpty, isEqual } from "lodash";
import { removeHtmlTags } from "utils/string";

import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";
import CustomCard from "components/CustomCard";
import { Autocomplete, Box, Grid, Stack, TableHead } from "@mui/material";
import MDTypography from "components/MDTypography";
import { FolderCopy } from "@mui/icons-material";
import DataListContainer from "components/DataListContainer";
import FormTextEditor from "components/FormTextEditor";
import CustomTextField from "components/Form/CustomTextField";
import GenericModal from "components/GenericModal";
import PanelActions from "components/PanelActions";
import TagList from "components/TagList";
import ConfirmPanelChanges from "components/ConfirmPanelChanges";
import CustomDescription from "./CustomDescription";
import CustomContentItem from "./CustomContentItem";

// const contentNames = [
//   { label: "Onboarding", value: "onboardingText" },
//   { label: "Acknowledgment", value: "acknowledgmentText" },
//   { label: "Apply Page", value: "applyPageText" },
//   { label: "Apply Page Background", value: "applyPageBackground" },
// ];

const contentTypes = ["HTML", "text"];
const emptyValues = (bool, tags) => {
  return {
    name: bool ? "jobDescription" : "onboardingText",
    type: "HTML",
    content: "<div><br></div>",
    ...(!!tags && bool && { tags }),
  };
};
const columnHeadings = [
  { name: "Name", sm: 3 },
  { name: "Type", sm: 1.5 },
  { name: "Modified Date", sm: 3.5 },
  { name: "Content", sm: 4 },
];

const CustomContentPanel = ({ currentData, onUpdate, isVenue = false, isCompanyJobs = false }) => {
  const { isMobile, setCurrentFormDirty, changePanel } = useAppContextController();

  const { dropdowns: contentNames } = useDropdowns({ entity: "contentNames" });
  const { dropdowns: jobContentNames } = useDropdowns({ entity: "jobContentNames" });
  const names = isCompanyJobs ? jobContentNames : contentNames;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm();

  const [allContent, setAllContent] = useState([]);
  const [tempContents, setTempContents] = useState(allContent);
  const [currentContent, setCurrentContent] = useState({});
  const [contentIndex, setContentIndex] = useState(null);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isContentsDirty, setContentsDirty] = useState(false);
  const [description, setDescription] = useState(`<p></p>`);
  const [stadiumPeopleInfo, setStadiumPeopleInfo] = useState(`<p></p>`);

  const selectedType = watch("type");

  const handleMethod = (method, data) => {
    switch (method) {
      case "create":
        setContentIndex(tempContents?.length);
        setCurrentContent(data[tempContents?.length]);
        break;
      case "remove":
        setContentIndex(null);
        setCurrentContent({});
        setShowDetails(false);
        break;
      case "update":
        setContentIndex(contentIndex);
        setCurrentContent(data[contentIndex]);
        break;
      default:
        break;
    }
  };

  const handleSuccess = (method, data) => {
    handleMethod(method, data);
    setContentsDirty(false);
  };

  const handleEmptyContentReset = () => {
    setContentIndex(null);
    setCurrentContent(emptyValues(isCompanyJobs, currentData?.tags));
    setShowDetails(false);
  };

  const handleContentsOrderChange = (contentsData) => {
    const updatedIndex = contentsData.findIndex((item) => item === currentContent);
    if (updatedIndex > -1) {
      setContentIndex(updatedIndex);
      setCurrentContent(contentsData[updatedIndex]);
    } else {
      handleEmptyContentReset();
    }
  };

  const handleSave = (data) => {
    let updatedContent;
    let method;
    const tags = data?.tags;
    const values = { ...data };

    delete values?.tags;

    if (
      removeHtmlTags(values?.content) !== "" &&
      !isEqual(currentContent, values) &&
      !isEqual(emptyValues(isCompanyJobs), values)
    ) {
      let updatedValues;
      if (contentIndex !== null) {
        updatedValues = tempContents.map((item, idx) => {
          if (contentIndex === idx)
            return { ...item, ...values, modifiedDate: new Date().toISOString() };
          return item;
        });
        updatedContent = { content: updatedValues };
      } else {
        updatedContent = {
          content: [
            ...tempContents,
            {
              createdDate: new Date().toISOString(),
              ...values,
              modifiedDate: new Date().toISOString(),
            },
          ],
        };
        method = "create";
      }
    } else {
      method = "remove";
      updatedContent = { content: tempContents };
    }

    if (isVenue) {
      if (description !== currentData?.description)
        updatedContent = { ...updatedContent, description };
      if (stadiumPeopleInfo !== currentData?.stadiumPeopleInfo)
        updatedContent = { ...updatedContent, stadiumPeopleInfo };
    }

    if (isCompanyJobs) {
      updatedContent = { ...updatedContent, tags };
    }

    if (updatedContent) onUpdate(updatedContent, method, handleSuccess);
  };

  const handleContent = (idx, row) => {
    setShowDetails(true);
    setContentIndex(idx);
    if (idx === null) setCurrentContent({});
    else setCurrentContent(row);
  };

  const handleRemoveContent = (idx) => {
    const tempContentList = tempContents;
    const updatedContent = tempContentList?.filter((_, index) => index !== idx);
    if (contentIndex !== null) handleContentsOrderChange(updatedContent);
    setTempContents(updatedContent);
  };

  const handleCancelChanges = () => {
    reset(currentContent);
    if (isVenue) {
      setDescription(currentData?.description);
      setStadiumPeopleInfo(currentData?.stadiumPeopleInfo);
    }
    handleContentsOrderChange(allContent);
    setTempContents(allContent);
    setContentsDirty(false);
  };

  const tableHead = (
    <TableHead sx={{ py: 1, zIndex: 3, position: "sticky", top: "0px", bgcolor: "#FFF" }}>
      <Grid container alignItems="center">
        <Grid item xs={11}>
          <Grid container spacing={1} alignItems="center">
            {columnHeadings.map((col) => (
              <Grid item key={col.name} xs={col.sm}>
                <MDTypography variant="body2" fontWeight="bold">
                  {col.name}
                </MDTypography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </TableHead>
  );

  const handleConfirmChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(handleSave)();
      changePanel();
      setCurrentFormDirty(false);
    }
  };

  useEffect(() => {
    if (currentData?.content) {
      setAllContent(currentData?.content);
      setTempContents(currentData?.content);
    } else {
      setAllContent([]);
      setTempContents([]);
    }
  }, [currentData, currentData?.content]);

  useEffect(() => {
    if (isEqual(tempContents, allContent)) setContentsDirty(false);
    else setContentsDirty(true);
  }, [tempContents]);

  useEffect(() => {
    if (!isEmpty(currentContent)) reset(currentContent);
    else reset(emptyValues(isCompanyJobs, currentData?.tags));
  }, [currentContent]);

  useEffect(() => {
    if (selectedType === "text") {
      setValue("content", removeHtmlTags(getValues("content")));
    }
  }, [selectedType]);

  useEffect(() => {
    setCurrentFormDirty(isDirty || isContentsDirty);
  }, [isDirty, isContentsDirty]);

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <CustomCard
        icon={<FolderCopy color="white" />}
        cardTitle={`${isCompanyJobs ? "Job" : "Custom"} Content: ${
          currentData?.name || currentData?.title
        }`}
        cardActions={
          (isDirty || isContentsDirty) && <PanelActions onCancel={handleCancelChanges} />
        }
        {...customPanelCard(isMobile, "primary")}
      >
        <Stack spacing={3} sx={{ ...styles.container, ...(isCompanyJobs && { height: 620 }) }}>
          {isVenue && (
            <Box>
              <CustomDescription
                description={description}
                setDescription={setDescription}
                stadiumPeopleInfo={stadiumPeopleInfo}
                setStadiumPeopleInfo={setStadiumPeopleInfo}
                setContentsDirty={setContentsDirty}
              />
            </Box>
          )}
          <Box>
            <DataListContainer
              onAdd={handleContent}
              data={tempContents}
              renderRow={(row, idx) => (
                <CustomContentItem
                  row={row}
                  idx={idx}
                  onEdit={handleContent}
                  onRemove={handleRemoveContent}
                />
              )}
              tableHeight={265}
              height={267}
              selected={contentIndex}
              tableHead={tableHead}
            />
          </Box>
          {showDetails && (
            <Box>
              <Stack direction="row" justifyContent="flex-start" spacing={5}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      options={names || []}
                      value={field.value || emptyValues(isCompanyJobs)?.name}
                      fullWidth
                      freeSolo={isCompanyJobs}
                      onInputChange={(e, v) => {
                        if (isCompanyJobs && currentContent?.name !== v)
                          setValue("name", v, { shouldDirty: true });
                      }}
                      onChange={(e, v) => {
                        setValue("name", v, { shouldDirty: true });
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant="standard"
                          type="text"
                          label="Name"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      options={contentTypes || []}
                      value={field.value || "HTML"}
                      fullWidth
                      onChange={(e, v) => {
                        setValue("type", v, { shouldDirty: true });
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant="standard"
                          type="text"
                          label="Type"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
              {selectedType === "HTML" && (
                <FormTextEditor
                  title="Content"
                  name="content"
                  onClick={() => setPreviewOpen(true)}
                  control={control}
                  height="14.025rem"
                />
              )}
              {selectedType === "text" && (
                <Box>
                  <MDTypography
                    component="label"
                    variant="caption"
                    color="secondary"
                    fontWeight="regular"
                  >
                    Content
                  </MDTypography>
                  <Controller
                    name="content"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        name="content"
                        variant="outlined"
                        type="text"
                        onChange={(e) => setValue("content", e.target.value)}
                        label=""
                        value={field.value || ""}
                        {...field}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        maxRows={9}
                        minRows={9}
                      />
                    )}
                  />
                </Box>
              )}
            </Box>
          )}
          {isCompanyJobs && (
            <Box>
              <Controller
                name="tags"
                control={control}
                defaultValue={watch("tags")}
                render={({ field }) => (
                  <TagList
                    {...field}
                    setValue={setValue}
                    setError={setError}
                    clearErrors={clearErrors}
                    currentComponent={currentData}
                  />
                )}
              />
              {errors?.invalidTag && (
                <MDTypography color="error">{errors?.invalidTag?.message}</MDTypography>
              )}
            </Box>
          )}
        </Stack>
        {isPreviewOpen && (
          <GenericModal
            open={isPreviewOpen}
            setOpen={setPreviewOpen}
            body={<Box p={3}>{parse(watch("content"))}</Box>}
            borderRadius={5}
            overflow="hidden"
            contentPadding={0}
            width="90%"
            maxWidth={1170}
            maxHeight={940}
          />
        )}
      </CustomCard>
      <ConfirmPanelChanges onConfirm={handleConfirmChanges} />
    </form>
  );
};

export default CustomContentPanel;
