import { useCallback, useEffect, useMemo, useState } from "react";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import fetchCompanyJob from "api/companies/fetchCompanyJob";
import fetchCustomers from "api/companies/fetchCustomers";

import { useAppContextController } from "context/AppContext";
import useCompanyCache from "hooks/useCompanyCache";
import useSort from "utils/useSort";

import moment from "moment";
// import CompanyJobIcon from "components/CompanyJobIcon";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import FiltersList from "components/FiltersList";
import Searchbar from "components/Searchbar";
import CompanyJobsTableActions from "layouts/pages/companyjobs/components/CompanyJobsTableActions";
import useSessionAuth from "hooks/useSessionAuth";
import useHandleBackButtonSubGrid from "hooks/useHandleBackButtonSubGrid";
import PictureAvatar from "components/PictureAvatar";
import { splitName } from "utils/helpers/splitName";
import { Close, DateRange } from "@mui/icons-material";
import DateRangePopover from "components/DateRangePopover";
import DateRangeSelector from "components/DateRangeSelector";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import {
  generateApplicantJobChartReport,
  generateApplicantJobTableReport,
  generateCompanyJobChartReport,
  generateCompanyJobTableReport,
  generateEmployeeJobChartReport,
  generateEmployeeJobTableReport,
  generateJobChartReport,
  generateJobTableReport,
} from "api/reports/jobReport";
import { exportReport } from "api/reports/exportReport";
import { saveReport } from "api/reports/saveReport";
import fetchModuleReports from "api/reports/fetchModuleReports";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import ReportModal from "components/ReportModal";
import PositionCount from "../PositionCount";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CompanyJobsTable = ({
  jobSlug,
  fetchAll,
  filters,
  setFilters,
  setOpen,
  setJobPreview,
  showFiltersList = true,
  showSearchBar = true,
  title = "Jobs",
  parent = "Jobs",
  refetchJobs,
  currentApplicant,
  setToastAlert,
  name,
  parentPage,
  parentNavigateWithBack,
}) => {
  const navigate = useNavigate();
  const classes = useStyle();
  const { currentJob, setCurrentJob, userType, venues, company, setCompany, setCompanyType } =
    useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const [dateRangeFilter, setDateRangeFilter] = useState(null);

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  const { order, orderBy, toggleSort } = useSort();
  const [customerData, setCustomerData] = useState({});
  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);
  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, includeExtraCounts: true, filters: { ...filters }, venues };
  const { logoutAuth0User } = useSessionAuth();

  // Handle back button from parent screens
  const { navigateWithBack, navigateWithoutBack } = useHandleBackButtonSubGrid({
    parentPage,
    setPage,
    page,
    filters,
    navigateFunc: navigate,
    navigateWithBackFunc: parentNavigateWithBack,
  });

  if (dateRangeFilter) {
    allOptions.startDate = dateRangeFilter.startDate.toISOString();
    allOptions.endDate = dateRangeFilter.endDate.toISOString();
  }

  const {
    data: jobs,
    isLoading,
    refetch,
  } = useQuery(["company_jobs_table", allOptions], () => fetchCompanyJob(allOptions), {
    onSuccess: async (data) => {
      // Assuming that data is an array of interviews
      const customers = {};

      try {
        // Get all company slugs
        let companySlugs = data?.data?.map((comp) => comp.companySlug);
        // Remove duplicates
        companySlugs = [...new Set(companySlugs)];

        const results = await fetchCustomers({
          filters: { slug: companySlugs.join(";") },
          page: 1,
          limit: 0,
        });

        // Do something with all fetched customer data

        // Assuming that 'results' is an array of objects and each object has a 'slug' property
        (results?.data ?? []).forEach((cstmr) => {
          customers[cstmr.slug] = cstmr;
        });
        setCustomerData(customers);
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    },
  });

  const { mutateAsync: createJobTableReport } = useMutation(generateJobTableReport);
  const { mutateAsync: createJobChartReport } = useMutation(generateJobChartReport);
  const { mutateAsync: createCompanyJobTableReport } = useMutation(generateCompanyJobTableReport);
  const { mutateAsync: createCompanyJobChartReport } = useMutation(generateCompanyJobChartReport);
  const { mutateAsync: createApplicantJobTableReport } = useMutation(
    generateApplicantJobTableReport
  );
  const { mutateAsync: createApplicantJobChartReport } = useMutation(
    generateApplicantJobChartReport
  );
  const { mutateAsync: createEmployeeJobTableReport } = useMutation(generateEmployeeJobTableReport);
  const { mutateAsync: createEmployeeJobChartReport } = useMutation(generateEmployeeJobChartReport);
  const { mutateAsync: exportJobReport, isLoading: isLoadingExport } = useMutation(exportReport);
  const { mutateAsync: saveJobReport, isLoading: isLoadingSave } = useMutation(saveReport);

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const handleLoadInitialJobReport = useCallback(async () => {
    if (openReportMessageModal) {
      try {
        let reportModule;

        if (parent === "Customers") {
          reportModule = `company-jobs:${filters?.companySlug}`;
        } else if (parent === "applicants") {
          reportModule = `applicant-jobs:${currentApplicant?._id}`;
        } else if (parent === "employees") {
          reportModule = `employee-jobs:${currentApplicant?._id}`;
        } else {
          reportModule = `jobs`;
        }

        const response = await fetchModuleReports(reportModule);

        const latestCriteria = getLatestCriteria(
          response?.tableReportData,
          response?.chartReportData,
          defaultReportModalValues
        );
        setReportModalValues(latestCriteria);

        // Set table details
        if (response?.tableReportData) {
          setChartTableSrc(response.tableReportData.queryDetails.iframeSrc);
          setTableQueryDetails(response.tableReportData.queryDetails);
          setTableReportData(response.tableReportData);
        }

        // Set chart details
        if (response?.chartReportData) {
          setChartSrc(response.chartReportData.queryDetails.iframeSrc);
          setChartQueryDetails(response.chartReportData.queryDetails);
          setChartReportData(response.chartReportData);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReportMessageModal]);

  useEffect(() => {
    handleLoadInitialJobReport();
  }, [handleLoadInitialJobReport]);

  const handleExportReport = useCallback(
    async (reportType, fileType) => {
      try {
        const payload = {
          queryId: reportType === "table" ? tableQueryDetails?.id : chartQueryDetails?.id,
          fileType,
        };

        let reportModule;

        if (parent === "Customers") {
          reportModule = `Customer `;
        } else if (parent === "applicants ") {
          reportModule = `Applicant `;
        } else if (parent === "employees") {
          reportModule = `Employee `;
        } else {
          reportModule = "";
        }

        await exportJobReport(payload, {
          onSuccess: async (response) => {
            setToastAlert({
              isShow: true,
              message: `${reportModule}Job ${reportType === "table" ? "Table" : "Chart"} ${
                fileType === "csv" ? " CSV" : " Excel"
              } Report has been successfully exported!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error exporting report:", error);
      }
    },
    [chartQueryDetails?.id, exportJobReport, parent, setToastAlert, tableQueryDetails?.id]
  );

  const handleGenerateReport = async (values) => {
    if (values?.formType === "table") {
      let reportPayload = {};

      // Add columns to payload if any are selected
      if (values?.tableColumns?.length > 0) {
        reportPayload = { ...reportPayload, columns: values?.tableColumns };
      }

      // Add sortBy and order to payload if available
      if (values?.sortBy) {
        reportPayload = { ...reportPayload, sortBy: values.sortBy };
      }

      if (values?.order) {
        reportPayload = { ...reportPayload, order: values.order };
      }

      if (values?.dateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.startDate, // ISO formatted date already handled
            end: values?.endDate, // ISO formatted date already handled
            field: values?.dateField,
            range: values?.dateRange,
          },
        };
      }

      // Add filters to payload if any filters are provided
      if (values?.filters) {
        reportPayload = {
          ...reportPayload,
          filters: values?.filters,
        };
      }

      if (values?.filterCards) {
        reportPayload = {
          ...reportPayload,
          filterCards: values?.filterCards,
        };
      }

      try {
        if (parent === "Customers") {
          reportPayload = {
            ...reportPayload,
            companySlug: filters?.companySlug,
          };

          await createCompanyJobTableReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartTableSrc(response?.iframe_url);
                setTableQueryDetails(response?.queryDetails);
                setTableReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Customer Job Table Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else if (parent === "applicants") {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          await createApplicantJobTableReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartTableSrc(response?.iframe_url);
                setTableQueryDetails(response?.queryDetails);
                setTableReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Applicant Job Table Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else if (parent === "employees") {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          await createEmployeeJobTableReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartTableSrc(response?.iframe_url);
                setTableQueryDetails(response?.queryDetails);
                setTableReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Employee Job Table Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else {
          await createJobTableReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartTableSrc(response?.iframe_url);
                setTableQueryDetails(response?.queryDetails);
                setTableReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Job Table Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        }
      } catch (error) {
        console.error("Error generating table report:", error);
      }
    } else {
      let reportPayload = {
        xAxis: values?.xAxis,
        yAxis: values?.yAxis,
        groupBy: values?.groupBy,
        chartType: values?.chartType,
      };

      try {
        if (parent === "Customers") {
          reportPayload = {
            ...reportPayload,
            companySlug: filters?.companySlug,
          };

          await createCompanyJobChartReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartSrc(response?.iframe_url);
                setChartQueryDetails(response?.queryDetails);
                setChartReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Company Job Chart Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else if (parent === "applicants") {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          await createApplicantJobChartReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartSrc(response?.iframe_url);
                setChartQueryDetails(response?.queryDetails);
                setChartReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Applicant Job Chart Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else if (parent === "employees") {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          await createEmployeeJobChartReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartSrc(response?.iframe_url);
                setChartQueryDetails(response?.queryDetails);
                setChartReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Employee Job Chart Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else {
          await createJobChartReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartSrc(response?.iframe_url);
                setChartQueryDetails(response?.queryDetails);
                setChartReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Job Chart Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        }
      } catch (error) {
        console.error("Error generating chart report:", error);
      }
    }
  };

  const handleSaveJobReport = useCallback(
    async (values) => {
      try {
        let payload = {
          criteria: values,
        };

        let _tableReportData = tableReportData;
        let _chartReportData = chartReportData;

        if (parent === "Customers") {
          _tableReportData = {
            ..._tableReportData,
            feature: `company-jobs:${filters?.companySlug}`,
          };

          _chartReportData = {
            ..._chartReportData,
            feature: `company-jobs:${filters?.companySlug}`,
          };
        } else if (parent === "applicants") {
          _tableReportData = {
            ..._tableReportData,
            feature: `applicant-jobs:${currentApplicant?._id}`,
          };

          _chartReportData = {
            ..._chartReportData,
            feature: `applicant-jobs:${currentApplicant?._id}`,
          };
        } else if (parent === "employees") {
          _tableReportData = {
            ..._tableReportData,
            feature: `employee-jobs:${currentApplicant?._id}`,
          };

          _chartReportData = {
            ..._chartReportData,
            feature: `employee-jobs:${currentApplicant?._id}`,
          };
        }

        if (values?.formType === "table") {
          payload = { ...payload, tableReportData: _tableReportData, formType: "table" };
        } else if (values?.formType === "chart") {
          payload = { ...payload, chartReportData: _chartReportData, formType: "chart" };
        }

        await saveJobReport(payload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            let reportModule;

            if (parent === "Customers") {
              reportModule = `Customer `;
            } else if (parent === "applicants ") {
              reportModule = `Applicant `;
            } else if (parent === "employees") {
              reportModule = `Employee `;
            } else {
              reportModule = "";
            }

            setToastAlert({
              isShow: true,
              message: `${reportModule}Job ${
                values?.formType === "table" ? "Table" : "Chart"
              } Report has been successfully saved!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error saving job report:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartReportData, saveJobReport, tableReportData]
  );

  const columns = useMemo(
    () => [
      {
        title: "Logo",
        field: "companySlug",
        customCell: (slug, field, row) => {
          const cust = customerData[slug] ? customerData[slug] : {};
          return (
            <>
              <CompanyLogoSquare company={cust} defaultCompany={company} />
            </>
          );
        },
      },
      { title: "Status", field: "status" },
      {
        title: "Customer",
        field: "companyName",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      { title: "Job Url", field: "jobSlug" },
      { title: "Title", field: "title" },
      {
        title: "Approving Manager",
        field: "approvingManager",
        customCell: (approvingManager, field, row) => {
          if (!approvingManager) return null;
          const { first, last } = splitName(approvingManager);

          return (
            <PictureAvatar
              image={null}
              firstName={first}
              lastName={last}
              // userId={usr?._id?.toString()}
              size="md"
            />
          );
        },
      },
      {
        title: "Recruiter",
        field: "recruiter",
        customCell: (recruiter, field, row) => {
          if (!recruiter) return null;
          const { first, last } = splitName(recruiter);

          return (
            <PictureAvatar
              image={null}
              firstName={first}
              lastName={last}
              // userId={usr?._id?.toString()}
              size="md"
            />
          );
        },
      },
      {
        title: "Pay Rate",
        field: "payRate",
        customCell: (rate) => `$${rate?.toFixed(2)}`,
      },
      {
        title: "Qty",
        field: "positionQuantity",
        customCell: (field) => {
          const result = field !== undefined && field !== null ? field : 1;
          return <span>{result}</span>;
        },
      },
      {
        title: "Date Created",
        field: "createdDate",
        customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
      },
      {
        title: "Job Actions",
        field: "jobSlug",
        onClick: (e) => e.stopPropagation(),
        customCell: (thisJobSlug, field, row, job) => {
          return (
            <CompanyJobsTableActions
              jobSlug={thisJobSlug}
              row={row}
              setOpen={setOpen}
              setJobPreview={setJobPreview}
              parent={parent}
              setPage={setPage}
              currentApplicant={currentApplicant}
              setNavigateUrl={navigateWithBack}
            />
          );
        },
      },
    ],
    [customerData, company, setOpen, setJobPreview, parent, currentApplicant, navigateWithBack]
  );

  const searchColumns = useMemo(
    () => [
      {
        width: 50,
        title: "Logo",
        field: "companySlug",
        customCell: (slug, field, row) => {
          const cust = customerData[slug] ? customerData[slug] : {};
          return (
            <>
              <CompanyLogoSquare company={cust} defaultCompany={company} />;
            </>
          );
        },
      },
      { title: "Status", field: "status", width: 50 },
      {
        width: 150,
        title: "Customer",
        field: "companySlug",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      { title: "Job Url", field: "jobSlug", width: 200 },
      { title: "Title", field: "title", width: 150 },
      {
        width: 40,
        title: "Pay Rate",
        field: "payRate",
        customCell: (rate) => `$${rate?.toFixed(2)}`,
      },
    ],
    [company, customerData]
  );
  const handleRowClick = (job, e) => {
    e.stopPropagation();
    setCurrentJob(job);
    const navigateFunc = parent?.toLowerCase() === "jobs" ? navigateWithoutBack : navigateWithBack;
    navigateFunc(`/companyjobs/${job.jobSlug}`);
  };

  const addNew = () => {
    if (currentJob?._id) setCurrentJob({});
    const navigateFunc = parent?.toLowerCase() === "jobs" ? navigateWithoutBack : navigateWithBack;
    navigateFunc("/companyjobs/create");
  };

  useEffect(() => {
    refetch();
  }, [refetchJobs]);

  useEffect(() => {
    setPage(1);
  }, [action, filters]);

  useEffect(() => {
    if (!openReportMessageModal) {
      setChartTableSrc("");
      setChartSrc("");
    }
  }, [openReportMessageModal]);

  const renderTitle = () => {
    let reportModule;

    if (parent === "Customers") {
      reportModule = `Customer Job Report`;
    } else if (parent === "applicants") {
      reportModule = `Applicant Job Report for ${name}`;
    } else if (parent === "employees") {
      reportModule = `Employee Job Report for ${name}`;
    } else {
      reportModule = "Job Report";
    }

    return reportModule;
  };

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="warning"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <Icon fontSize="large">work</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              {title}
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center">
          <PositionCount data={jobs?.data} />
          <DateRangeSelector
            setDateRangeFilter={setDateRangeFilter}
            dateRangeFilter={dateRangeFilter}
          />
          {showSearchBar && (
            <Searchbar
              fetch={fetchCompanyJob}
              fetchAll={false}
              placeholder="Search Customer Jobs"
              columns={searchColumns}
              queryCharacterLimit={2}
              resultsLimit={10}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["title", "name", "companySlug", "jobSlug"]}
              filterBy="jobSlug"
            />
          )}
          <MDBox>
            <IconButton className={classes.addButton} color="info" onClick={addNew}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>

          {userType !== "User" && userType !== "Client" && userType !== "Event Admin" && (
            <Tooltip title="Create Report">
              <IconButton color="info" onClick={() => setOpenReportMessageModal(true)}>
                <ViewComfyIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={jobs}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          isLoading={isLoading}
          defaultSort="jobSlug"
          idField="jobSlug"
        />
      </MDBox>
      {pageLimitConfirmationModal}

      {openReportMessageModal && (
        <ReportModal
          reportingType="jobs"
          message="Coming Soon!"
          isOpen={openReportMessageModal}
          toggleOpen={setOpenReportMessageModal}
          handleGenerateReport={handleGenerateReport}
          chartTableSrc={chartTableSrc}
          chartSrc={chartSrc}
          title={renderTitle()}
          filterCardList={
            ["applicants", "employees"].includes(parent) || parent === "Customers"
              ? []
              : ["Active", "Inactive", "Hidden", "All"]
          }
          handleExportReport={handleExportReport}
          handleSaveReport={handleSaveJobReport}
          isLoadingExport={isLoadingExport}
          isLoadingSave={isLoadingSave}
          reportModalValues={reportModalValues}
        />
      )}
    </Card>
  );
};

// Setting default values for the props
CompanyJobsTable.defaultProps = {
  fetchAll: true,
  title: "Jobs Table",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
CompanyJobsTable.propTypes = {
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default CompanyJobsTable;
